import {
    FormData,
    ModalDropdownForm,
    ModalHeader,
    ModalTextAreaForm,
    StateTemplate,
    validateInput,
} from "../Components/Modals";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";

function supportTypes() {
    return [
        {value: "Issue Encountered", label: "Issue Encountered"},
        {value: "Suggestion", label: "Suggestion"},
        {value: "Custom Analytic", label: "Custom Analytic"},
        {value: "Feedback", label: "Feedback"},
        {value: "Sales Request", label: "Sales Request"},
        {value: "Other", label: "Other"},
    ]
}

function defaultFormData(defaultSupportType = supportTypes()[0]) {
    return {
        type: new StateTemplate({
            label: defaultSupportType,
            value: defaultSupportType
        }, true, true, false, validateInput),
        desc: new StateTemplate("", true, false, false, validateInput),
    }
}


export default function SupportRequest({show, close, noteDispatch, defaultSupportType}) {
    const [form, setForm] = useState(new FormData(defaultFormData(defaultSupportType)));
    function submitSupportRequest() {
        if (!form.validate()) {
            noteDispatch({
                type: 'add',
                notification: {
                    text: "Invalid Request",
                    details: "Please ensure all fields are filled out correctly.",
                    type: "error"
                }
            });
            return;
        }
        noteDispatch({
            type: 'add',
            notification: {
                text: "Request Submitted",
                details: "If necessary, a Tenasol representative will be in contact to assist.",
                type: "ok"
            }
        });
        close();
    }

    useEffect(() => {
        const escapeCheck = (e) => {
            if (e.key === "Escape") {
                close()
            }
        }
        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [close])

    if (!show) return null;


    return (
        <div className="modal-outer">
            <div className="modal-wrapper">
                <div id="modal-add-files" className="modal-background dash-modal">
                    <ModalHeader text="Support Request" close={close}/>
                    <div className="white white-link">
                        <p>To reach Tenasol Support you may:<br/>
                            email us at <a href="mailto:support@tenasol.com">support@tenasol.com</a><br/>
                            call us at <a href="tel:support@tenasol.com">202.888.1757</a><br/>
                            completing the below request form<br/>
                        </p>
                        <p>
                            We will get back to you at the earliest possible convenience.
                        </p>
                    </div>
                    <ModalDropdownForm label="Support Type"
                                       formData={form}
                                       setForm={setForm}
                                       options={supportTypes()}
                                       size="large"
                                       dataId="type"
                                       tabIndex={1}
                    />
                    <ModalTextAreaForm label="Description"
                                       formData={form}
                                       setForm={setForm}
                                       size="large"
                                       dataId="desc"
                                       tabIndex={2}
                                       autoFocus={true}
                    />
                    <button className="primary-button" onClick={submitSupportRequest} tabIndex={3}>
                        Submit Request
                    </button>
                    <button className="secondary-button" onClick={close} tabIndex={4}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

SupportRequest.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    noteDispatch: PropTypes.func.isRequired,
    defaultSupportType: PropTypes.object,
}