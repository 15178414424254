import {CircleMarker, MapContainer, Rectangle, TileLayer, useMap, useMapEvent} from 'react-leaflet';
import {useCallback, useMemo, useState} from "react";
import {useEventHandlers} from '@react-leaflet/core'
import "./PatientGeo.css"

const locations = [{lat: 39.0348, lng: -76.9075, size: 40, number: 20},
    {lat: 39.2, lng: -77.1, size: 20, number: 10},
    {lat: 39.1, lng: -77.2, size: 10, number: 5},
    {lat: 39.3, lng: -77.3, size: 5, number: 2},
    {lat: 38.9, lng: -76.9, size: 37, number: 50},
    {lat: 39, lng: -77.2, size: 55, number: 140},
    {lat: 39.1, lng: -77.1, size: 30, number: 30},
    {lat: 39.2, lng: -77.2, size: 20, number: 10},
    {lat: 39.3, lng: -77.1, size: 10, number: 5},
    {lat: 39.4, lng: -77.2, size: 5, number: 2},
    {lat: 39.5, lng: -77.1, size: 37, number: 50},
]


export function PatientGeo() {
    return (<ReactControlExample/>);
}


// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

const BOUNDS_STYLE = {weight: 1}

function MinimapBounds({parentMap, zoom}) {
    const minimap = useMap()

    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback((e) => {
        parentMap.setView(e.latlng, parentMap.getZoom())
    }, [parentMap],)
    useMapEvent('click', onClick)

    // Keep track of bounds in state to trigger renders
    const [bounds, setBounds] = useState(parentMap.getBounds())
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        // Update the minimap's view to match the parent map's center and zoom
        minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])

    // Listen to events on the parent map
    const handlers = useMemo(() => ({move: onChange, zoom: onChange}), [onChange])
    useEventHandlers({instance: parentMap}, handlers)

    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE}/>
}

function MinimapControl({position, zoom}) {
    const parentMap = useMap()
    const mapZoom = zoom || 0

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(() => (<MapContainer
        style={{height: 80, width: 80}}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
        <MinimapBounds parentMap={parentMap} zoom={mapZoom}/>
    </MapContainer>), [mapZoom, parentMap],)

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (<div className={positionClass}>
        <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>)
}

function ReactControlExample() {
    return (<MapContainer center={[39, -77]} zoom={10} scrollWheelZoom={true}
                          style={{width: "100%", height: "calc(100% - 460px)"}}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MinimapControl position="topright"/>
        {locations.map((location, index) => (<GeoMarker
            location={location}
            key={index}/>))}
    </MapContainer>)
}

function GeoMarker({location, index}) {
    const center = [location.lat, location.lng]
    const stroke = '#FFD52F'
    const fill = "#A68A1E80"
    return (
        <CircleMarker
            key={index}
            center={center}
            radius={location.size}
            pathOptions={{color: stroke, fill: fill, }}>
            <div className="white">{location.number}</div>
            </CircleMarker>
    );
}