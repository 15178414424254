import PropTypes from "prop-types";
import {FileList} from "./FileList";
import {PatientList} from "./PatientList";
import {FindingList} from "./FindingList";
import {AnalyticsPage} from "./Analytics";

export function ProjectContent({activePage, currentProject, noteDispatch}) {
    const activeTile = activePage - 5;
    switch (activePage) {
        case 1:
            return <FileList currentProject={currentProject} noteDispatch={noteDispatch}/>
        case 2:
            return <PatientList currentProject={currentProject} noteDispatch={noteDispatch}/>
        case 3:
            return <FindingList currentProject={currentProject} noteDispatch={noteDispatch}/>
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            return <AnalyticsPage activeTile={activeTile} noteDispatch={noteDispatch}/>
        default:
            return <FileList currentProject={currentProject} noteDispatch={noteDispatch}/>
    }
}

ProjectContent.propTypes = {
    activePage: PropTypes.number.isRequired,
    currentProject: PropTypes.object.isRequired,
    noteDispatch: PropTypes.func.isRequired
};



