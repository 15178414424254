import "./Header.css";
import UserIcon from "../Components/UserIcon";
import gearIcon from '../images/icons/gear.svg';
import helpIcon from '../images/icons/help.svg';
import tenasolLogo from '../images/tenasol-logo.png';
import PropTypes from "prop-types";
import {useState} from "react";
import {UserProfileDropdown} from "./UserProfileDropdown";
import {SupportDropdown} from "./SupportDropdown";
import SupportRequest from "./SupportRequest";
import {Link} from "react-router-dom";


export default function Header({user, noteDispatch, currentProject}) {
    const [showUserProfileDropdown, setShowUserProfileDropdown] = useState(false);
    const [showSupportDropdown, setShowSupportDropdown] = useState(false);
    const [showSupportRequestModal, setShowSupportRequestModal] = useState(false);

    function clickSupport(event) {
        setShowSupportDropdown(!showSupportDropdown)
        setShowUserProfileDropdown(false)
    }

    function clickProfile(event) {
        setShowUserProfileDropdown(!showUserProfileDropdown)
        setShowSupportDropdown(false)
    }

    return (
        <div className="header">
            <div className="header-left flex-row">
                <HeaderNav currentProject={currentProject}/>
                <img className="header-logo" src={tenasolLogo} alt="Tenasol"/>
            </div>
            <div className="header-right">
                <div className="header-icon" onClick={clickSupport}>
                    <img src={helpIcon} alt="Support Request"/>
                    <SupportDropdown show={showSupportDropdown}
                                     supportRequest={setShowSupportRequestModal}
                                     close={() => {
                                         setShowSupportDropdown(true)
                                     }}
                    />
                </div>
                <div className="header-icon"><img src={gearIcon} alt="Settings"/></div>
                <div className="header-icon" onClick={clickProfile}>
                    <UserIcon user={user}/>
                    {showUserProfileDropdown &&
                        <UserProfileDropdown show={showUserProfileDropdown}
                                             user={user}
                                             noteDispatch={noteDispatch}
                        />}
                </div>
            </div>
            <SupportRequest show={showSupportRequestModal}
                            noteDispatch={noteDispatch}
                            close={() => {
                                setShowSupportRequestModal(false)
                            }}/>

        </div>
    );
}
Header.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    noteDispatch: PropTypes.func.isRequired,
    currentProject: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
}


function HeaderNav({currentProject}) {
    const location = window.location.pathname;
    const projectId = currentProject ? currentProject.id : 1;
    const map = {
        '/patient': <NavLocation label='Project' to={"/project/" + projectId + "#Patients"}/>,
        '/file': <NavLocation label='Project' to={"/project/" + projectId}/>,
        '/dashboard': null,
        'default': null,
    };


    function NavMap(location) {
        for (let key in map) {
            if (location.startsWith(key)) {
                return map[key];
            }
        }
        return map['default'];
    }

    return (
        NavMap(location)
    )

}

function NavLocation({label, to}) {
    return (
        <>
            <Link to={to} className="flex-row gap-10">
                <svg width="28" height="28" viewBox="0 0 24 24">
                    <path fill="#ffffff" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
                </svg>
                <div className="font-14 white">{label}</div>
                <div className="vertical-divider"></div>
            </Link>
        </>

    )
}

NavLocation.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}
