export default function LoginHeader() {
    return (
        <div className="login-header">
            <a id="login-back" href="https://tenasol.com">
                <svg id="login-back-arrow" width="28" height="28" viewBox="0 0 24 24">
                    <path fill="#ffffff" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
                </svg>
                <span>Back to Tenasol.com</span>
            </a>
        </div>
    );
}