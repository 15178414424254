import loadingSpinner from '../images/TenasolLoading.gif'

export function LoadingModal() {
    return (
        <div className="modal-outer">
            <div className="modal-wrapper">
                <img src={loadingSpinner} alt="loading" style={{height: "75px"}}/>
                <div className="loading-modal__text">Loading...</div>
            </div>
        </div>
    );
}