export function PrivacyPolicyModal(props) {
    return (
        <div id="privacy-policy-modal" className="modal-outer">
            <div className="modal-wrapper">
                <div id="privacy-policy-modal-overlay" className="login-modal-overlay">
                    {privacyPolicyText()}
                    <div className="primary-button" id="privacy-button" onClick={props.hideModal}>Close</div>
                </div>
            </div>
        </div>
    );
}

function privacyPolicyText() {
    return (
        <div id="privacy-modal-text">
            <h1>Privacy Policy</h1>
            <p>Effective: March 1st, 2024<br/>Last Updated: March 1st, 2024</p>
            <p>At Tenasol, we recognize the paramount importance of personal information privacy and
                security, and we approach these matters with utmost seriousness concerning any individual who visits
                our site or utilizes our services. This Privacy Policy outlines the procedures by which Tenasol
                Technologies, Inc. and/or its affiliates (“Tenasol”, “Us”, “We”, “Our”) gather, utilize,
                maintain, and disclose information obtained from users (each referred to as a "User",
                “Client”, “You”, “Your”) of the <a href="https://tenasol.com">tenasol.com</a> website (“Services”).
                Please note that this policy does
                not extend to links leading to other websites from our Services, and we bear no
                responsibility for the privacy practices, security practices, or content of these external sites.</p>
            <h2>Agreement of Use</h2>
            <p>By using Tenasol Services, you are agreeing to this Privacy Policy as well as the additional
                terms as set forth in the Terms of Use. Services of Tenasol are not permitted for anyone
                under the age of 13, and as such, if you are under 13 years, cease using our Services. As a normal
                part of operations, we may process data provided by clients and partners that contain
                information about patients about or under the age of 13 years of age. If you are aware that
                we may have collected information directly from someone 13 years of age or younger, please let
                us know by contacting us.</p>
            <h2>Data Collection</h2>
            <p>Our services regularly collect and process Personally Identifiable Information PII (Personal
                Identifiable Information) as well as PHI (Personal Health Information) as a part of normal
                operations (in total “Personal Information”). You agree that by registering with Services,
                you are representing that you will keep registration information secure, such as username and
                password credentials, and prevent unauthorized access to information. You are responsible
                for maintaining the confidentiality and security of your password. Opting out of providing
                information may limit Services we are able to offer to you. Personal Information that we
                collect includes, but is not limited to:</p>
            <ul>
                <li>Contact Information - Such as name, phone number, email address, title, organization,
                    username, and password.
                </li>
                <li>Demographic Information - Such as age, gender, ethnicity, or address.</li>
                <li>Insurance Information - Such as plan, primary physician, and insurance number.</li>
                <li>Healthcare Information - Such as medical history, previous providers, visit history, and
                    relevant data that may assist in your care.
                </li>
                <li>Activity Information – Such as interactions within our website including traffic,
                    duration, web browser used, device type, free text submissions, etc. The website may use cookies
                    to optimize experience, a standard, industry-wide web technology which uses small files
                    stored on a User’s device to do so. Note that cookies do not contain Personal Information.
                    Blocking the use of cookies may limit website functionality.
                </li>
            </ul>
            <p>Furthermore, we may generate statistical data or models derived from this information, or in
                combination with other information, to better improve experience or Services offered.</p>
            <h2>Personal Information Rights</h2>
            <p>As a resident of California, you have the right to request Tenasol to delete any Personal
                Information that Tenasol has collected about you. You also have the right to request from
                Tenasol information about what Personal Information it has collected, used, disclosed, and
                “sold” about you, as defined under the CCPA (California Consumer Privacy Act). The term
                “sold” encompasses various actions such as selling, renting, releasing, disclosing, disseminating,
                making available, transferring, or otherwise communicating a consumer’s personal information
                by the business to another business or a third party for monetary or other valuable
                consideration.</p>
            <p>Additionally, you have the right to request that we cease any such sales. It's important to
                note that Tenasol does not exchange consumers’ Personal Information for monetary compensation.
                However, we do share Personal Information with our clients and third parties as part of our
                normal business operations. Please be aware that we may require further information from you
                to process your request. To make a request, please contact us via information provided in the
                Contact section.</p>
            <h2>Information Use</h2>
            <p>Personal Information may be collected, used, and in some cases shared (see “Information
                Sharing” section), by Tenasol, its affiliates, employees, consultants, and third-party vendors to
                improve our Services. Subject to restrictions and obligations of applicable laws, we may use
                Personal Information collected for communication with you, managing interactions, managing
                transactions, performing Services, customer support, research and development, optimization of Services,
                security, debugging, and fulfilling legal obligations as required by law.</p>
            <h2>Security</h2>
            <p>The personal information we collect is safeguarded and kept confidential in accordance with
                legal and HIPAA (Health Insurance Portability and Accountability Act) requirements. To ensure
                this, we undertake measures such as, but not limited to:</p>
            <ul>
                <li>Conducting pre-employment background checks, continuous exclusion checks, continuous
                    training, and requiring agreement to our Code of Conduct and internal Privacy and
                    Security Policy.
                </li>
                <li>Conducting IT assessments at least annually.</li>
                <li>Conducting diligent evaluations and continuous reviews of vendors we engage with.</li>
            </ul>
            <p>If you discover any security vulnerabilities or incidents, such as a lost or unlocked device
                or exposure of passwords and usernames, please notify us promptly, and we will take appropriate
                action to address the issue. In the event of a data breach that may have compromised your
                information, we will promptly inform you.</p>
            <h2>Information Changes</h2>
            <p>To best serve our clients, we attempt to make sure that all information is accurate and up to
                date. If you feel our information is inaccurate or incorrect, please let us know immediately
                and we will either make the change for you after verifying your identity or direct you how to do
                so within the Services.</p>
            <h2>Information Sharing</h2>
            <p>To provide Services, Tenasol may share information with third parties who use our Services or
                who provide services to us, such as our customers, vendors, and operators of software used in
                our Services. Any third party who we engage with in this capacity will be held to the same
                information security standard as ourselves with that information and will be contractually
                obligated to use the information only for the purpose intended by that relationship. While
                we do not sell personal information, we may share Personal Information related to our customers
                for business purposes.</p>
            <p>Except as otherwise stated in this Privacy Policy, Personal Information will remain private
                unless we believe in good faith that your Private Information is required to meet legal
                requirements, or to defend or protect either our Users or Tenasol.</p>
            <h2>Information Retention</h2>
            <p>We will retain information as long as a User account remains active or as needed to provide
                that User with Services, or as required by applicable law. If you would like to cancel your
                account or request that we discontinue use of your information, please contact us. We may ask you to
                verify your personal identity before doing so. Note that following account cancellation we
                may retain and use your information as necessary to comply with our legal obligations, resolve
                disputes, and enforce our agreements. In the event we go through a business transition such
                as a merger, acquisition, bankruptcy, or sale of all or a portion of our assets, your information
                may be among the assets transferred.</p>
            <h2>Notifications</h2>
            <p>We reserve the right to send welcome emails to newly registered users for the purpose of
                verifying their username or password. Additionally, we may send notifications about updates
                to our services and provide updates regarding issues pertaining to your account. In all forms
                of communication, we provide clear guidance on how to opt-out. By not opting out, you consent
                to receiving these communications.</p>
            <h2>Changes</h2>
            <p>We reserve the right to amend this document at any time. Any changes will be effective upon
                the posting of the revised Privacy Policy unless otherwise stated in the revised sections. As
                such, by continuing use of our Services after changes are made, you are consenting to the
                then-current Privacy Policy. If you do not agree, discontinue use of the Service immediately. An
                indication of when this document was last updated is located at the top of this page.</p>
            <h2>Contact</h2>
            <p>If you have any questions or concerns about this document, please contact us
                at <a href="mailto:hello@tenasol.com">hello@tenasol.com</a>, or send correspondence to the
                following address:</p>
            <p>Tenasol Technologies, Inc.<br/>
                Privacy & Security Officer<br/>
                3401 Sharon Chapel Road<br/>
                Alexandria, VA 22310</p>
        </div>
    );
}