import tenasolLogo from "../images/tenasol-logo.png";
import phoneIcon from "../images/icons/phone.svg";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import PropTypes from "prop-types";

export function TwoFA({dispatch, hideModal, setLoading}) {
    const navigate = useNavigate();
    const [error, setError] = useState(false)

    function verify2Fa() {
        let code = document.getElementById('2fa-1').value + document.getElementById('2fa-2').value + document.getElementById('2fa-3').value + document.getElementById('2fa-4').value + document.getElementById('2fa-5').value + document.getElementById('2fa-6').value
        if (code.includes("9")) {
            dispatch({type: 'add', notification: {text: "Login Successful", type: "ok"}})
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                navigate('/dashboard');
            }, 800)
        } else {
            setError(true)
        }
    }

    function requestNewCode() {
        dispatch({
            type: 'add', notification: {
                text: "new code sent to your phone", details: "Check your phone for the new 2FA pass code.", type: "ok"
            }
        })
    }

    function validateInput(e) {
        setError(false)
        e.target.value = e.target.value.replace(/[^0-9]/g, '')
        let faId = e.target.id.split('-')[1]
        if (e.target.value.length > 1) {
            e.target.value = e.target.value[1]
        }
        if (e.target.value.length === 1) {
            if (faId === '6') {
                verify2Fa()
            } else {
                let nextId = `2fa-${parseInt(faId) + 1}`
                document.getElementById(nextId).focus()
            }
        }
    }

    function onKeyDown(e) {
        if (e.key === "Backspace") {
            let faId = e.target.id.split('-')[1]
            if (faId !== '1') {
                let prevId = `2fa-${parseInt(faId) - 1}`
                document.getElementById(prevId).focus()
            }
        }
    }

    function errorMessage(error) {
        if (error) {
            return (
                <div>Invalid. Try again or <span className="pointer underline" onClick={requestNewCode}> request another code.</span>
                </div>);

        } else {
            return null;
        }
    }

    return (<div id="two-fa-modal" className="modal-outer">
        <div className="modal-wrapper">
            <div className="login-modal-overlay">
                <div className="password-reset-modal">
                    <a href="https://tenasol.com" className="login-tenasol-logo"><img src={tenasolLogo} alt="Tenasol"/></a>
                    <div className="align-center m-25-top"><img src={phoneIcon} alt="Phone icon"/></div>
                    <div className="login-box-title font-16 white">Check your phone!</div>
                    <div className="font-10 white login-b-width align-center m-8-bottom">
                        Enter the 6 digit code sent to your phone.
                    </div>
                    <div id="wrapper-2fa">
                        <input id="2fa-1" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={10} autoFocus/>
                        <input id="2fa-2" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={11} />
                        <input id="2fa-3" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={12} />
                        <div className="font-18 white twofa-dash">-</div>
                        <input id="2fa-4" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={13} />
                        <input id="2fa-5" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={14} />
                        <input id="2fa-6" className="twofa-cell" onChange={validateInput} onKeyDown={onKeyDown} tabIndex={15} />
                    </div>
                    <div id="login-failed-message"
                         className="font-10 align-center">{errorMessage(error)}</div>
                    <button className="primary-button login-b-width m-10-top" onClick={verify2Fa} tabIndex={16}>Confirm</button>
                    <button className="secondary-button login-b-width m-10-top" onClick={hideModal} tabIndex={17} >Cancel</button>
                </div>
            </div>
        </div>
    </div>);
}

TwoFA.propTypes = {
    dispatch: PropTypes.func.isRequired, hideModal: PropTypes.func.isRequired, setLoading: PropTypes.func.isRequired,
}

