import PropTypes from "prop-types";

export default function UserIcon({user, sizeOverride}) {
    return (
        <div className="user-profile-icon" style={sizeOverride ? {width: sizeOverride, height:sizeOverride} : {}}>
            <img
                src={user.image} alt="User Profile"/>
        </div>
    );
}
UserIcon.propTypes = {
    user: PropTypes.object.isRequired,
    sizeOverride: PropTypes.string
}