import Header from "../Header/Header";
import PropTypes from "prop-types";
import "./Project.css"
import {SidebarIcon} from "./SidebarIcon";
import {ProjectContent} from "./ProjectContent";
import {useMatch} from 'react-router-dom'
import {ProjectHeader} from "./ProjectHeader";


export default function Project({projects, members, noteDispatch}) {
    const pathMatch = useMatch('/project/:lastPart')
    const projectId = pathMatch ? pathMatch.params.lastPart : null
    const subPage = window.location.hash.split('#')[1]
    const currentUser = members.find(member => member.id === 1);
    const currentProject = projects.find(project => project.id === parseInt(projectId));
    const activePage = pageNum(subPage);

    function pageNum(subPage){
        switch (subPage) {
            case "Documents":
                return 1
            case "Patients":
                return 2
            case "Findings":
                return 3
            case "Analytics-Home":
                return 4
            case "Analytics-Gender":
                return 5
            case "Analytics-Age":
                return 6
            case "Analytics-Race":
                return 7
            case "Analytics-Geo":
                return 8
            case "Analytics-Codes":
                return 9
            case "Analytics-Request":
                return 10
            default:
                return 1
        }
    }

    return (
        <div className="app-content">
            <Header user={currentUser} noteDispatch={noteDispatch}/>
            <div className="project-remaining">
                <ProjectSidebar activePage={activePage}/>
                <ProjectHeader currentProject={currentProject}/>
                <ProjectContent activePage={activePage} currentProject={currentProject} noteDispatch={noteDispatch}/>
            </div>
        </div>
    )
}
Project.propTypes = {
    projects: PropTypes.array.isRequired,
    members: PropTypes.array.isRequired,
    noteDispatch: PropTypes.func.isRequired
}

function ProjectSidebar({activePage}) {
    return (
        <div className="project-sidebar">
            <SidebarIcon icon={homeIcon()}
                         active={false}
                         subLink="/dashboard"
            />
            <SidebarIcon icon={documentIcon()}
                         active={activePage === 1}
                         subLink="#Documents"
            />
            <SidebarIcon icon={personIcon()}
                         active={activePage === 2}
                         subLink="#Patients"
            />
            <SidebarIcon icon={findingsIcon()}
                         active={activePage === 3}
                         subLink="#Findings"
            />
            <SidebarIcon icon={graphIcon()}
                         active={activePage >= 4}
                         subLink="#Analytics-Home"
            />
        </div>
    )
}
ProjectSidebar.propTypes = {
    activePage: PropTypes.number.isRequired
}

function documentIcon() {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector"
                      d="M4.6195 10.4506H13.3805C13.6833 10.4506 13.9277 10.2042 13.9277 9.90107C13.9277 9.59796 13.6833 9.35156 13.3805 9.35156H4.6195C4.31662 9.35156 4.07227 9.59796 4.07227 9.90107C4.07227 10.2042 4.31662 10.4506 4.6195 10.4506Z"/>
                <path id="Vector_2"
                      d="M4.6195 14.2992H13.3805C13.6833 14.2992 13.9277 14.0528 13.9277 13.7497C13.9277 13.4466 13.6833 13.2002 13.3805 13.2002H4.6195C4.31662 13.2002 4.07227 13.4466 4.07227 13.7497C4.07227 14.0528 4.31662 14.2992 4.6195 14.2992Z"/>
                <path id="Vector_3"
                      d="M4.6195 18.1478H13.3805C13.6833 18.1478 13.9277 17.9014 13.9277 17.5983C13.9277 17.2952 13.6833 17.0488 13.3805 17.0488H4.6195C4.31662 17.0488 4.07227 17.2952 4.07227 17.5983C4.07227 17.9014 4.31662 18.1478 4.6195 18.1478Z"/>
                <path id="Vector_4"
                      d="M2.97676 21.9969H15.0232C16.5325 21.9969 17.7616 20.7638 17.7616 19.2471V6.96207C17.7616 6.22801 17.4762 5.53726 16.9597 5.01868L12.7676 0.808115C12.2512 0.289536 11.5633 0.00292969 10.8312 0.00292969H2.97665C1.46737 0.00292969 0.238281 1.23597 0.238281 2.75264V19.2483C0.238281 20.7638 1.46623 21.998 2.97665 21.998L2.97676 21.9969ZM15.893 5.50121H13.9289C13.0233 5.50121 12.2861 4.76098 12.2861 3.85165V1.87939L15.893 5.50121ZM1.334 2.7515C1.334 1.84217 2.07119 1.10194 2.97676 1.10194H10.8313C10.9535 1.10194 11.0736 1.11843 11.1907 1.14524V3.85162C11.1907 5.36715 12.4186 6.60134 13.9291 6.60134H16.6232C16.6499 6.7199 16.6673 6.83949 16.6673 6.96217V19.2472C16.6673 20.1566 15.9302 20.8968 15.0246 20.8968H2.97702C2.07144 20.8968 1.33426 20.1566 1.33426 19.2472L1.334 2.7515Z"/>
            </g>
        </svg>
    );
}

function personIcon() {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector"
                      d="M10.0007 12.0002C11.5256 12.0002 12.9881 11.3919 14.0664 10.3092C15.1447 9.22645 15.7504 7.7579 15.7504 6.22667C15.7504 4.69543 15.1447 3.22688 14.0664 2.1441C12.9881 1.0614 11.5256 0.453125 10.0007 0.453125C8.47579 0.453125 7.01329 1.0614 5.93498 2.1441C4.85674 3.22688 4.25098 4.69543 4.25098 6.22667C4.25098 7.7579 4.85674 9.22645 5.93498 10.3092C7.01329 11.3919 8.47579 12.0002 10.0007 12.0002ZM10.0007 2.10282C11.0899 2.10282 12.1345 2.53729 12.9047 3.3107C13.6749 4.0841 14.1076 5.13303 14.1076 6.22669C14.1076 7.32035 13.6749 8.36932 12.9047 9.14268C12.1345 9.91608 11.0899 10.3506 10.0007 10.3506C8.91155 10.3506 7.86691 9.91608 7.09674 9.14268C6.32653 8.36927 5.89385 7.32035 5.89385 6.22669C5.89385 5.13303 6.32653 4.08406 7.09674 3.3107C7.86696 2.53729 8.91155 2.10282 10.0007 2.10282Z"/>
                <path id="Vector_2"
                      d="M12.4643 13.6494H7.53602C5.5754 13.6494 3.69503 14.4315 2.30876 15.8236C0.922421 17.2157 0.143555 19.1038 0.143555 21.0725C0.143555 21.7287 0.403155 22.3581 0.865252 22.8222C1.32737 23.2862 1.95418 23.5468 2.6077 23.5468H17.3925C18.0461 23.5468 18.6729 23.2862 19.135 22.8222C19.5971 22.3581 19.8567 21.7287 19.8567 21.0725C19.8567 19.1038 19.0778 17.2156 17.6915 15.8236C16.3051 14.4315 14.4248 13.6494 12.4642 13.6494H12.4643ZM17.3926 21.8972H2.60774C2.3899 21.8972 2.18097 21.8104 2.02695 21.6557C1.87285 21.501 1.78636 21.2912 1.78636 21.0725C1.78636 19.5412 2.39212 18.0727 3.47036 16.9899C4.54867 15.9072 6.01117 15.2989 7.53609 15.2989H12.4644C13.9893 15.2989 15.4518 15.9072 16.5301 16.9899C17.6083 18.0727 18.2141 19.5412 18.2141 21.0725C18.2141 21.2912 18.1276 21.501 17.9735 21.6557C17.8195 21.8104 17.6106 21.8972 17.3927 21.8972H17.3926Z"/>
            </g>
        </svg>
    );
}

function findingsIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99053 12.4953C9.99053 12.9994 9.58338 13.4065 9.07925 13.4065C8.57515 13.4065 8.16797 12.9994 8.16797 12.4953C8.16797 11.9912 8.57512 11.584 9.07925 11.584C9.58335 11.584 9.99053 12.0009 9.99053 12.4953Z"/>
            <path
                d="M10.3389 5.60262L10.3196 5.75772L9.82514 9.86819C9.82514 10.2851 9.48584 10.6244 9.06896 10.6244C8.65208 10.6244 8.31277 10.2851 8.31277 9.86819L7.81836 5.75772V5.60262C7.81836 5.08879 8.38066 4.68164 9.07864 4.68164C9.77663 4.68164 10.3389 5.08879 10.3389 5.60262Z"/>
            <path
                d="M15.8937 13.7063C15.8963 13.7025 15.8991 13.699 15.9016 13.6952C17.0873 11.9599 17.5826 9.84447 17.2904 7.76314C16.9982 5.6818 15.9395 3.78451 14.3218 2.44277C12.704 1.10099 10.6438 0.411457 8.5441 0.50912C6.44462 0.606863 4.45715 1.48472 2.97092 2.97089C1.48474 4.45706 0.606796 6.4444 0.509133 8.54403C0.411389 10.6435 1.10098 12.7037 2.44271 14.3216C3.78444 15.9396 5.68177 16.9981 7.76312 17.2902C9.84447 17.5825 11.9599 17.0872 13.6952 15.9015C13.699 15.8989 13.7026 15.8961 13.7063 15.8936L20.86 23.047C21.2506 23.4377 21.82 23.5903 22.3537 23.4472C22.8874 23.3043 23.3043 22.8874 23.4472 22.3537C23.5903 21.82 23.4377 21.2506 23.047 20.86L15.8937 13.7063ZM8.94644 15.9063C7.26718 15.9075 5.64398 15.302 4.37563 14.2012C3.10746 13.1005 2.27964 11.5786 2.0447 9.91581C1.80965 8.2531 2.18325 6.56145 3.09667 5.15226C4.01011 3.74314 5.40186 2.71156 7.01575 2.24707C8.62959 1.78281 10.3567 1.91719 11.8793 2.62549C13.4019 3.33379 14.6173 4.56829 15.3018 6.10176C15.9863 7.63521 16.0937 9.36441 15.6043 10.9708C15.115 12.5772 14.0617 13.9526 12.6385 14.8441C11.5316 15.5371 10.2524 15.9052 8.94644 15.9063Z"/>
        </svg>
    );
}

function graphIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M8.90234 0.791992H15.096V19.6274H8.90234V0.791992Z"/>
                <path id="Vector_2" d="M16.6943 8.01367H22.888V19.6278H16.6943V8.01367Z"/>
                <path id="Vector_3" d="M1.1123 11.0215H7.30594V19.6268H1.1123V11.0215Z"/>
                <path id="Vector_4" d="M0.8125 20.8008H23.1869V23.2079H0.8125V20.8008Z"/>
            </g>
        </svg>
    );
}


function homeIcon(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
            <path
                d="M5.05464 9.70703C4.46439 9.70703 3.98535 10.186 3.98535 10.7763V18.7934C3.98535 20.5612 5.4244 22.0002 7.19218 22.0002H16.8086C18.5764 22.0002 20.0154 20.5612 20.0154 18.7934V10.7763C20.0154 10.1861 19.5364 9.70703 18.9461 9.70703C18.3559 9.70703 17.8769 10.186 17.8769 10.7763V18.7934C17.8769 19.3826 17.3968 19.8627 16.8076 19.8627H7.19222C6.60298 19.8627 6.12294 19.3826 6.12294 18.7934V10.7763C6.12294 10.1861 5.64392 9.70703 5.05365 9.70703H5.05464Z"
                fill="#FBFBFB"/>
            <path
                d="M2.44824 8.44913L11.3974 2.32922C11.7642 2.07868 12.2372 2.07868 12.603 2.32922L21.5511 8.4502C21.7345 8.57647 21.946 8.6376 22.1534 8.6376C22.4941 8.6376 22.8298 8.47525 23.0363 8.1716C23.37 7.68457 23.2447 7.01915 22.7577 6.68543L13.8117 0.56445C12.7124 -0.18815 11.2914 -0.18815 10.191 0.56445L1.24287 6.68543C0.755843 7.01913 0.630563 7.68356 0.964287 8.1716C1.29698 8.65964 1.96341 8.78492 2.45046 8.45019L2.44824 8.44913Z"
                fill="#FBFBFB"/>
        </svg>
    );
}