import {
    FormData,
    ModalDropdownForm,
    ModalDropdownUsers,
    ModalHeader,
    ModalTextForm,
    StateTemplate,
    validateInput,
    validateText
} from "../Components/Modals";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import shieldIcon from "../images/icons/shield.svg";
import {initialMembers} from "../Data";


export const yearOptions = [
    {value: "2021", label: "2021"},
    {value: "2022", label: "2022"},
    {value: "2023", label: "2023"},
    {value: "2024", label: "2024"}
];

export const deadlineOptions = [
    {value: "Q1", label: "Q1"},
    {value: "Q2", label: "Q2"},
    {value: "Q3", label: "Q3"},
    {value: "Q4", label: "Q4"}
];

export const lobOptions = [
    {
        "label": "Generalized Extraction",
        "value": "Generalized Extraction"
    },
    {
        "label": "CMS HCC V2223",
        "value": "CMS HCC V2223"
    },
    {
        "label": "CMS HCC V2423",
        "value": "CMS HCC V2423"
    },
    {
        "label": "CMS HCC V2823",
        "value": "CMS HCC V2823"
    },
    {
        "label": "ESRD E2123",
        "value": "ESRD E2123"
    },
    {
        "label": "ESRD E2423",
        "value": "ESRD E2423"
    },
    {
        "label": "HHS-HCC V07",
        "value": "HHS-HCC V07"
    },
    {
        "label": "RxHCC R0523",
        "value": "RxHCC R0523"
    },
    {
        "label": "RxHCC R0823",
        "value": "RxHCC R0823"
    },
    {
        "label": "HEDIS 2021",
        "value": "HEDIS 2021"
    },
    {
        "label": "HEDIS 2022",
        "value": "HEDIS 2022"
    },
    {
        "label": "HEDIS 2023",
        "value": "HEDIS 2023"
    }
];

export const documentDeduplicationOptions = [
    {value: "None", label: "None"},
    {value: "Very Restricted", label: "Very Restricted - 100% identical documents"},
    {value: "Restricted", label: "Restricted - identical findings and page count"},
    {value: "Medium", label: "Medium - identical findings"},
    {value: "Loose", label: "Loose - identical patient and encounters"},
    {value: "Very Loose", label: "Very Loose - identical patient and dates of service"}
];

const currentUser = {
    value: initialMembers[0].id,
    label: initialMembers[0].name,
    image: initialMembers[0].image,
    isFixed: true
};

function defaultFormData() {
    return {
        name: new StateTemplate("", true, false, false, validateText),
        deadline: new StateTemplate({value: "", label: ""}, true, true, false, validateInput),
        lob: new StateTemplate({value: "", label: ""}, true, false, false, validateInput),
        assessment_year: new StateTemplate({value: "", label: ""}, true, true, false, validateInput),
        dedupe: new StateTemplate({value: "None", label: "None"}, true, true, false, validateInput),
        desc: new StateTemplate("", false, true),
        members: new StateTemplate([currentUser], false, true)
    }
}

export default function NewProjectModal({allUsers, projects, close, next, noteDispatch}) {
    const [form, setForm] = useState(new FormData(defaultFormData()));

    useEffect(() => {
        const escapeCheck = (e) => {
            if (e.key === "Escape") {
                close()
            }
        }
        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [close])

    function createProject() {
        const valid = form.validate();
        if (!valid) {
            noteDispatch({
                type: 'add',
                notification: {text: "Form Invalid", details: "Please check the form for errors", type: "error"}
            });
            return;
        }
        const memberIds = form.state.members.value.map(member => member.value);
        let newProject = {
            id: projects.length,
            name: form.state.name.value,
            deadline: form.state.deadline.value.value,
            lob: form.state.lob.value.value,
            assessment_year: form.state.assessment_year.value.value,
            desc: form.state.desc.value,
            documents: 0,
            members: memberIds,
            icon: shieldIcon,
        }
        let details = `${newProject.name} may now be found in the Dashboard.`;
        noteDispatch({type: 'add', notification: {text: "Project Created", details: details, type: "ok"}});
        setForm(new FormData(defaultFormData));
        next(newProject);
    }

    return (
        <div className="modal-outer">
            <div className="modal-wrapper">
                <div id="modal-new-project" className="modal-background dash-modal">
                    <ModalHeader text="New Project" close={close}/>
                    <div className="dash-modal-row">
                        <ModalTextForm label="Project Name"
                                       size="medium"
                                       placeholder="Enter Text"
                                       formData={form}
                                       setForm={setForm}
                                       dataId={"name"}
                                       tabIndex={1}
                        />
                        <ModalDropdownForm label="Deadline"
                                           size="short"
                                           id="new-proj-deadline"
                                           placeholder="Enter Project Name"
                                           options={deadlineOptions}
                                           formData={form}
                                           setForm={setForm}
                                           dataId={"deadline"}
                                           tabIndex={2}
                        />
                    </div>
                    <div className="dash-modal-row">
                        <ModalDropdownForm label="Project Type"
                                           size="medium"
                                           id="new-proj-lob"
                                           options={lobOptions}
                                           formData={form}
                                           setForm={setForm}
                                           dataId={"lob"}
                                           tabIndex={3}
                        />
                        <ModalDropdownForm label="Assessment Year"
                                           size="short"
                                           id="new-proj-year"
                                           options={yearOptions}
                                           formData={form}
                                           setForm={setForm}
                                           dataId={"assessment_year"}
                                           tabIndex={4}
                        />
                    </div>
                    <ModalDropdownForm label="Document Deduplication"
                                       size="large"
                                       id="new-proj-document-deduplication"
                                       placeholder="Enter Deduplication Settings"
                                       options={documentDeduplicationOptions}
                                       formData={form}
                                       setForm={setForm}
                                       dataId={"dedupe"}
                                       tabIndex={5}


                    />

                    <ModalTextForm label="Description"
                                   size="large"
                                   id="new-proj-desc"
                                   placeholder="Enter Text"
                                   formData={form}
                                   setForm={setForm}
                                   dataId={"desc"}
                                   tabIndex={6}
                    />
                    <ModalDropdownUsers id="new-proj-members"
                                        label="Add Team Members"
                                        subLabel="Add up to 20 people at a time"
                                        size="large"
                                        allUsers={allUsers}
                                        formData={form}
                                        setForm={setForm}
                                        dataId={"members"}
                                        tabIndex={7}
                    />
                    <button className="primary-button" onClick={createProject} tabIndex={7}>
                        Create Project
                    </button>
                    <button className="secondary-button" onClick={close} tabIndex={8}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

NewProjectModal.propTypes = {
    allUsers: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    noteDispatch: PropTypes.func.isRequired,
}