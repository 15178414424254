import {useState} from "react";
import PropTypes from "prop-types";

export function LoadingModal({loadingTime, setLoadingTime}) {
    const [now, setTime] = useState(new Date().valueOf());
    setTimeout(() => {
        setTime(new Date().valueOf());
    }, 100);
    const remainingTime = loadingTime - now;
    const timeout = 2000.0;
    const progress = Math.max(Math.min(1, (timeout - remainingTime) / timeout), 0) * 100.0;
    if (progress >= 100) {
        setLoadingTime(0);
    }
    return (
        <div className="modal-outer">
            <div className="modal-wrapper">
                <div className="loading-modal flex-column align-center centered">
                    <div className="font-17 white">Transferring to Tenasol ...</div>
                    <div className="loading-bar">
                        <div className="loading-bar-inner" style={{width: progress * 3.0}}>
                        </div>
                    </div>
                    <div className="font-16 white centered">{progress.toFixed(0)}%</div>
                </div>
            </div>
        </div>
    );
}
LoadingModal.propTypes = {
    loadingTime: PropTypes.number.isRequired,
    setLoadingTime: PropTypes.func.isRequired
};