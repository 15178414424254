export function NotificationReducer(state, action) {
    switch (action.type) {
        case 'add': {
            const note = action.notification;
            return [
                ...state,
                {
                    type: note.type,
                    text: note.text,
                    details: note.details,
                    time: Date.now(),
                },
            ]
        }
        case 'remove': {
            return state.filter((note, index) => index !== action.index);
        }
        case 'update': {
            let now = Date.now();
            return state.filter(note => now - note.time < 8000);
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}