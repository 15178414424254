export function ProjectsReducer(state, action) {
    switch (action.type) {
        case 'add': {
            return [
                action.project,
                ...state,
            ]
        }
        case 'remove': {
            return state.filter((project, index) => index !== action.index);
        }
        case 'update': {
            return state.map((project) => {
                const newProject = action.project;
                if (project.id === newProject.id) {
                    return newProject;
                }
                return project;
            });
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}