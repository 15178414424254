import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import {initialMembers} from "../Data";
import {DashIconRow} from "./Dashboard";
import CollaboratorsRow from "../Components/CollaboratorsRow";

export function DashRows({projects, members, settings}) {
    let columnDefs = projectColumns;
    columnDefs[4].cellRendererParams = {
        settings: settings
    }
    columnDefs[7].cellRendererParams = {
        settings: settings
    }
    return (
        <div className="ag-theme-alpine" style={{height: "calc(100% - 72px)"}}>
            <AgGridReact
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                    sizeColumnsToFit: true,
                }}
                rowSelection="multiple"
                rowData={projects}
                columnDefs={columnDefs}
                width="100%"
                gridOptions={{
                    onGridReady: (event) => event.api.sizeColumnsToFit()
                }
                }
            />
        </div>
    );
}

DashRows.propTypes = {
    projects: PropTypes.array.isRequired,
    members: PropTypes.array.isRequired,
    settings: PropTypes.func.isRequired,
}

export const projectColumns = [
    {field: "", checkboxSelection: true, width: "64px", headerCheckboxSelection: true, filter:false, resizable: false},
    {field: "name", headerName: "Project Name", cellRenderer: Project},
    {field: "documents", headerName: "Documents", cellRenderer: Documents},
    {field: "", headerName: "Owner", cellRenderer: Owner},
    {field: "", headerName: "Collaborators", cellRenderer: Collaborators},
    {field: "assessment_year", headerName: "Assessment Year"},
    {field: "lob", headerName: "Project Type"},
    {field: "", headerName: "Actions", cellRenderer: Actions, width: "140px",},
];

export function Documents(props) {
    return (
        <div>{props.value.toLocaleString()}</div>
    )
}

export function Project(props) {
    const filename = props.value;
    const projectId = props.data.id;
    return (
        <Link to={"/project/" + projectId}>{filename}</Link>
    )
}

export function Owner(props) {
    const data = props.data;
    const memberId = data.members[0];
    const owner = initialMembers.find(member => member.id === memberId);
    return (
        <div>{owner.name}</div>
    )
}

export function Collaborators(props) {
    const project = props.data;
    const settings = props.settings;

    return (
        <CollaboratorsRow collaborators={project.members} members={initialMembers} projectId={project.id}
                          settings={settings}/>
    )
}


export function Actions(props) {
    const project = props.data;
    const settings = props.settings;

    return (
        <DashIconRow settings={settings} projectId={project.id}/>
    )
}