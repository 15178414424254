import PropTypes from "prop-types";
import Header from "../Header/Header";

export function TermsPage({members, noteDispatch}) {
    const currentUser = members.find(member => member.id === 1);
    return (
        <div className="app-content">
            <Header user={currentUser} noteDispatch={noteDispatch}/>
            <iframe src={process.env.PUBLIC_URL + "/terms_and_conditions.pdf"}
                    className="app-remaining"
                    style={{width: "100%"}}
                    title="terms_and_conditions"
            />
        </div>
    )
}

TermsPage.propTypes = {
    members: PropTypes.array.isRequired,
    noteDispatch: PropTypes.func.isRequired
}