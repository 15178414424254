import UserIcon from "../Components/UserIcon";
import PropTypes from "prop-types";
import "../Components/Dropdown.css"
import {DropdownDivider, DropdownItem, DropdownLink} from "../Components/Dropdown";

export function UserProfileDropdown({show, user, noteDispatch}) {
    if (!show) return null;
    return (
        <div className="dropdown-anchor">
            <div className="dropdown-wrapper">
                <div className="dropdown-background">
                    <DropdownItem label="ACCOUNT" title={true}/>
                    <UserSection user={user}/>
                    <DropdownLink label="Manage Account" to=""/>
                    <DropdownDivider/>
                    <DropdownLink label="Log Out" to='/login' title={false} toggle={() => {
                        noteDispatch({
                            type: 'add',
                            notification: {text: "Logged Out", details: "You have successfully logged out.", type: "ok"}
                        });
                    }}/>
                </div>
            </div>
        </div>
    );
}

UserProfileDropdown.defaultProps = {
    show: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    noteDispatch: PropTypes.func.isRequired,
}

function UserSection({user}) {
    return (
        <div className="dropdown-item">
            <UserIcon user={user}/>
            <div className="white white-link">
                <div className="username">{user.name}</div>
                <a className="email" href={`mailto:${user.email}`}>{user.email}</a>
            </div>
        </div>
    );
}