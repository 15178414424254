import Notification from "./Notification";

export default function Notifications(props) {
    setTimeout(() => {
        props.dispatch({type: 'update'})
    }, 8100);
    return (
        <div className="notifications">
            {props.notifications.length && props.notifications.map((notification, index) => {
                return <Notification key={index}
                                     index={index}
                                     text={notification.text}
                                     type={notification.type}
                                     details={notification.details}
                                     close={() => props.dispatch({type: 'remove', index: index})}
                />
            })}
        </div>
    );
}