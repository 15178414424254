import PropTypes from "prop-types";


export function ProjectHeader({currentProject}) {
    return (<div className="analytics-header">
        <div className="analytics-header-left p-16-sides">
            <div className="font-17 white">{currentProject.name}</div>
            <div className="font-12 grey">Project Type: {currentProject.lob}</div>
        </div>
        <div className="analytics-header-right font-12 grey">
            <div className="flex-column align-end gap-14 p-16-sides">
                <div>Assessment Year:</div>
                <div>Due Date:</div>
            </div>
            <div className="flex-column align-end gap-14 p-16-sides">
                <div>{currentProject.assessment_year}</div>
                <div>{currentProject.deadline}</div>
            </div>
        </div>
    </div>);
}
ProjectHeader.propTypes = {
    currentProject: PropTypes.object.isRequired,
};