import {AgGridReact} from "ag-grid-react";
import PropTypes from "prop-types";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import {Link} from "react-router-dom";
import downloadIcon from "../images/icons/download.svg";
import moreIcon from "../images/icons/more_vert.svg";
import {documents, initialMembers} from "../Data";
import {useEffect, useRef, useState} from "react";
import './FileList.css';
import ProjectDetailsModal from "./ProjectDetailsModal";


export function FileList({currentProject, noteDispatch}) {
    //const projectDocuments = documents.filter(doc => doc.id < currentProject.documents) // FIXME - Filter by project
    const projectDocuments = documents;


    function downloadSelected() {
        noteDispatch({
            type: "add",
            notification: {
                text: "Download Started",
                details: "Check the 'Downloads' section of your browser",
                type: "ok"
            }
        });
    }

    return (
        <>
            <FileListHeader currentProject={currentProject}
                            downloadSelected={downloadSelected}
                            numDocuments={projectDocuments.length}
            />
            <div className="ag-theme-alpine ">
                <AgGridReact
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}
                    rowSelection="multiple"
                    rowData={projectDocuments}
                    columnDefs={documentColumns}
                    gridOptions={{
                        onGridReady: (event) => event.api.sizeColumnsToFit()
                    }}
                />
            </div>
        </>
    )
}

FileList.propTypes = {
    currentProject: PropTypes.object.isRequired,
    noteDispatch: PropTypes.func.isRequired
};

export function FileListHeader({currentProject, downloadSelected, numDocuments}) {

    const [showDropDown, setShowDropDown] = useState(false);
    const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
    const dropDownRef = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            console.log("Document clicked");
            // Check if the dropdown is visible and the click was outside the dropdown
            console.log(dropDownRef.current);
            if (showDropDown && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
                console.log("Click outside the dropdown detected");
                setShowDropDown(false);
            }
        };

        // Add click event listener
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Remove event listener on cleanup
            console.log("Cleanup event listener");
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showDropDown]);

    return (
        <div className="project-header">
            <div className="p-16-sides flex-row gap-16">
                <div className="secondary-button lite-theme"
                     onClick={() => {
                         return true;
                     }}>
                    MARK AS NOT REVIEWED
                </div>
                <div className="secondary-button lite-theme"
                     onClick={() => {
                         return true;
                     }}>
                    MARK AS REVIEWED
                </div>
                <div className="secondary-button lite-theme"
                     onClick={() => {
                         return true;
                     }}>
                    ARCHIVE
                </div>
                <div className="secondary-button lite-theme"
                     onClick={() => {
                         return true;
                     }}>
                    ADD DOCUMENT
                </div>
            </div>
            <div className="float-right flex-row gap-16 p-16-sides">
                <div>Project: {currentProject.name}</div>
                <div>{numDocuments.toLocaleString()} Documents</div>
                <div className="icon-row-right pointer" onClick={downloadSelected}><img src={downloadIcon}
                                                                                        alt="Download"/>
                </div>
                <div className="icon-row-right pointer" onClick={() => setShowDropDown(!showDropDown)}><img
                    src={moreIcon} alt="More"/></div>
                {showProjectDetailsModal &&
                    <ProjectDetailsModal setShowModal={setShowProjectDetailsModal} allUsers={initialMembers} project={currentProject}/>}
            </div>
        </div>
    );
}

FileListHeader.propTypes = {
    currentProject: PropTypes.object.isRequired,
    downloadSelected: PropTypes.func.isRequired
};


export const documentColumns = [
    {field: "", checkboxSelection: true, width: "64px", headerCheckboxSelection: true, filter: false, resizable: false},
    {field: "filename", headerName: "File Name", cellRenderer: File},
    {field: "file_category", headerName: "File Type"},
    {field: "pages", headerName: "Pages"},
    {field: "patient_last_name", headerName: "Patient Last Name"},
    {field: "patient_first_name", headerName: "Patient First Name"},
    {field: "date_of_service_earliest", headerName: "DOS Min"},
    {field: "date_of_service_latest", headerName: "DOS Max"},
    {field: "priority", headerName: "Priority"},
];


export function File(props) {
    const filename = props.value;
    return (
        <Link to={"/file/" + filename}>{filename}</Link>
    )
}