import PropTypes from "prop-types";

import React, {useEffect, useState} from 'react';
import {
    FormData,
    ModalDropdownForm,
    ModalDropdownUsers,
    ModalHeader,
    ModalTextForm,
    StateTemplate,
    validateInput,
    validateText
} from '../Components/Modals';
import {initialMembers} from '../Data';
import {deadlineOptions, documentDeduplicationOptions, lobOptions, yearOptions} from "../Dashboard/NewProjectModal";

function defaultFormData(project) {
    let projectMembers = convertToOptions(initialMembers.filter(member => project.members.includes(member.id)));
    projectMembers[0].isFixed = true; // Set owner to non-removable

    function convertToOptions(users) {
        return users.map(user => ({
            value: user.id, label: user.name, image: user.image,
        }));
    }

    function validMembers(members) {
        return members.length > 0;
    }

    return {
        name: new StateTemplate(project.name, true, true, false, validateText),
        deadline: new StateTemplate({value: project.deadline, label: project.deadline}, true, true, false, validateInput),
        lob: new StateTemplate({value: project.lob, label: project.lob}, true, true, false, validateInput),
        assessment_year: new StateTemplate({value: project.assessment_year, label: project.assessment_year}, true, true, false, validateInput),
        dedupe: new StateTemplate({value: project.dedupe, label: project.dedupe}, true, true, false, validateInput),
        desc: new StateTemplate(project.desc, false, true),
        members: new StateTemplate(projectMembers, true, false, false, validMembers),
    }
}


export default function EditProjectDetailsModal({setShowModal, allUsers, project, noteDispatch, manageProject}) {
    console.log("Edit Project Details Modal", project)
    const [form, setForm] = useState(new FormData(defaultFormData(project)));

    useEffect(() => {
        const escapeCheck = (e) => {
            if (e.key === "Escape") {
                setShowModal(false);
            }
        }
        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [setShowModal])

    function updateProject() {
        const newMembersIds = form.state.members.value.map(member => member.value);
        console.log("preupdate project members", project.lob, form.state.lob.value)
        project.members = allUsers.filter(member => newMembersIds.includes(member.id)).map(member => member.id);
        project.name = form.state.name.value;
        project.deadline = form.state.deadline.value.value;
        project.lob = form.state.lob.value.value;
        project.assessment_year = form.state.assessment_year.value.value;
        project.dedupe = form.state.dedupe.value.value;
        project.desc = form.state.desc.value;
        console.log("Updating project members", project.lob, form.state.lob.value)
        console.log("Updating project members", form, project)
        noteDispatch({
            type: 'add',
            notification: {
                text: "Changes Saved",
                details: "Updated applied to '" + project.name + "' project",
                type: "ok"
            }
        });
        manageProject({type: "update", project: project});
        setShowModal(false);
    }


    return (<div className="modal-outer">
        <div className="modal-wrapper">
            <div id="modal-add-files" className="modal-background dash-modal">
                <ModalHeader text="Edit Project Details" close={() => {
                    setShowModal(false)
                }}/>
                <div className="dash-modal-row">
                    <ModalTextForm label="Project Name"
                                   size="medium"
                                   placeholder="Enter Text"
                                   formData={form}
                                   setForm={setForm}
                                   dataId={"name"}
                                   tabIndex={1}
                    />
                    <ModalDropdownForm label="Deadline"
                                       size="short"
                                       options={deadlineOptions}
                                       formData={form}
                                       setForm={setForm}
                                       dataId={"deadline"}
                                       tabIndex={2}
                    />
                </div>
                <div className="dash-modal-row">
                    <ModalDropdownForm label="Project Type"
                                       size="medium"
                                       options={lobOptions}
                                       formData={form}
                                       setForm={setForm}
                                       dataId={"lob"}
                                       tabIndex={3}
                    />
                    <ModalDropdownForm label="Assessment Year"
                                       size="short"
                                       options={yearOptions}
                                       formData={form}
                                       setForm={setForm}
                                       dataId={"assessment_year"}
                                       tabIndex={4}
                    />
                </div>
                <ModalDropdownForm label="Document Deduplication"
                                   size="large"
                                   id="new-proj-document-deduplication"
                                   placeholder="Enter Deduplication Settings"
                                   options={documentDeduplicationOptions}
                                   formData={form}
                                   setForm={setForm}
                                   dataId={"dedupe"}
                                   tabIndex={5}
                />
                <ModalTextForm label="Description"
                               size="large"
                               placeholder="Enter Text"
                               formData={form}
                               setForm={setForm}
                               dataId={"desc"}
                               tabIndex={6}
                />
                <ModalDropdownUsers label="Update Team Members"
                                    subLabel="Add up to 20 people at a time"
                                    size="large"
                                    allUsers={allUsers}
                                    formData={form}
                                    setForm={setForm}
                                    dataId={"members"}
                                    tabIndex={7}
                />
                <div className="primary-button" onClick={updateProject}>
                    Confirm
                </div>
                <div className="secondary-button" onClick={() => setShowModal(false)}>
                    Cancel
                </div>
            </div>
        </div>
    </div>);
};

EditProjectDetailsModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    allUsers: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    noteDispatch: PropTypes.func.isRequired,
    manageProject: PropTypes.func.isRequired,
}


