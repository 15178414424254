import PropTypes from "prop-types";
import "../Components/Dropdown.css"
import {DropdownDivider, DropdownItem, DropdownLink} from "../Components/Dropdown";


export function SupportDropdown({show, close, supportRequest}) {
    if (!show) return null;
    return (
        <div className="dropdown-anchor" onClick={null}>
            <div className="dropdown-wrapper">
                <div className="dropdown-background">
                    <DropdownItem label="SUPPORT" title={true}/>
                    <DropdownItem label="User Guide/ FAQ" newTab={true} toggle={() => {
                        return true;
                    }}/>
                    <DropdownItem label="Support Request" toggle={() => {
                        supportRequest(true);
                    }}/>
                    <DropdownDivider/>
                    <DropdownItem label="LEGAL" title={true}/>
                    <DropdownLink label="Privacy Policy" newTab={true} to="/privacy"/>
                    <DropdownLink label="Terms and Conditions" newTab={true} to="/terms"/>
                </div>
            </div>
        </div>
    );
}

SupportDropdown.defaultProps = {
    show: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    supportRequest: PropTypes.func.isRequired,
}