import PropTypes from "prop-types";
import newTabUIcon from "../images/icons/newTab.svg";
import {Link} from "react-router-dom";

export function DropdownItem({label, title = false, newTab = false, toggle}) {
    const titleClass = title ? 'title' : '';
    const clickable = toggle ? 'clickable' : '';

    return (
        <div className={`dropdown-item white ${clickable}`} onClick={toggle}>
            <div className={titleClass}>{label}</div>
            {newTab &&
                <div className="icon-row-right float-right">
                    <img src={newTabUIcon} alt="Opens in new tab" className="new-tab-icon"/>
                </div>
            }
        </div>
    );
}
DropdownItem.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.bool,
    newTab: PropTypes.bool,
    toggle: PropTypes.func,
};

export function DropdownLink({label, newTab = false, toggle, to}) {
    const target = newTab ? "_blank" : "";
    return (
        <Link className={`dropdown-item white clickable`} to={to} onClick={toggle} target={target}>
            <div>{label}</div>
            {newTab &&
                <div className="icon-row-right float-right">
                    <img src={newTabUIcon} alt="Opens in new tab" className="new-tab-icon"/>
                </div>
            }
        </Link>
    );
}
DropdownLink.propTypes = {
    label: PropTypes.string.isRequired,
    newTab: PropTypes.bool,
    to: PropTypes.string.isRequired,
    toggle: PropTypes.func,
};
export function DropdownDivider() {
    return <div className="dropdown-divider"/>;
}

