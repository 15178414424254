import PropTypes from "prop-types";
import {useState} from "react";


export function DetailTimeline({name, setActiveTab, cluster}) {
    const [dots, setDots] = useState(cluster);
    const number = dots.map(dot => dot.number).reduce((partialSum, a) => partialSum + a, 0);


    function toggleActive(id) {
        let newDots = [...dots];
        newDots.map(dot => {
            if (dot.id === id) {
                dot.active = !dot.active;
            }
            return dot;
        })
        setDots(newDots);
        setActiveTab(name);
    }

    return (<div>
        <div className="font-16 white">{name} <span className="font-11">(<span onClick={() => setActiveTab(name)}
                                                                            className="underline yellow">{number}</span>)</span>
        </div>
        <div className="patient-slider" style={{width: "100%"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18">
                <path
                    d="M9.41641 0.25L11.4727 2.30625L4.79349 9L11.4727 15.6938L9.41641 17.75L0.666407 9L9.41641 0.25Z"
                    fill="#FFCD29"/>
            </svg>
            <div className="slider-rail"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                <path
                    d="M2.58359 0.25L0.527344 2.30625L7.20651 9L0.527344 15.6938L2.58359 17.75L11.3336 9L2.58359 0.25Z"
                    fill="#616161"/>
            </svg>
        </div>
        {dots.map((dot, index) => <SliderDot key={index}
                                             data={dot}
                                             setActive={() => {
                                                 toggleActive(dot.id);
                                             }}
        />)}
        <SliderCalendarMonths startingMonth={5}/>
        <SliderCalendarYear startingMonth={5}/>
    </div>);
}

DetailTimeline.propTypes = {
    name: PropTypes.oneOf("Documents", "Findings").isRequired,
    setActiveTab: PropTypes.func.isRequired,
    cluster: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.number,
        month: PropTypes.number,
        number: PropTypes.number,
        active: PropTypes.bool
    })).isRequired
}

export function SliderDot({key, data, setActive}) {
    const size = data.number < 10 ? 32 : data.number < 100 ? 40 : 50;
    const translate = `translate(${size / 2}px, -33px)`
    const activeState = data.active ? "active" : ""
    const position = (data.month - 1) / 11 * 100;

    function activate() {
        console.log(data.id)
        setActive(data.id)
    }

    return (<div className="dots-slider" style={{}} key={key}>
        <div className="slider-dot" style={{width: `${position}%`, height: 0}}>
            <div style={{width: size, height: size, transform: translate}} className={activeState}
                 onClick={activate}>{data.number}</div>
        </div>
    </div>)
}

SliderDot.propTypes = {
    key: PropTypes.number.isRequired, data: PropTypes.shape({
        year: PropTypes.number, month: PropTypes.number, number: PropTypes.number,
    })
}

export function SliderCalendarMonths({startingMonth}) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const displayMonths = months.slice(startingMonth, 11).concat(months.slice(0, startingMonth + 1));
    return (<div className="slider-calendar font-10">
        {displayMonths.map((month, index) => <div key={index} className="month">{month}</div>)}
    </div>)
}

export function SliderCalendarYear({startingMonth}) {
    let yearLabels = ["", "", "", "", "", "", "", "", "", "", "", ""];
    yearLabels[startingMonth + 1] = "2023";
    return (<div className="slider-calendar font-10">
        {yearLabels.map((month, index) => <div key={index} className="year">{month}</div>)}
    </div>)
}

SliderCalendarMonths.propTypes = {
    startingMonth: PropTypes.number.isRequired
}

const initialDocs = [{id: 1, year: 2023, month: 1, number: 8, active: true}, {
    id: 2, year: 2023, month: 2, number: 25, active: true
}, {id: 3, year: 2023, month: 7, number: 129, active: false}, {
    id: 4, year: 2023, month: 9, number: 1, active: false
}, {id: 5, year: 2023, month: 6, number: 2, active: true},]

const initialFindings = [{id: 1, year: 2023, month: 1, number: 48, active: true}, {
    id: 2, year: 2023, month: 2, number: 386, active: true
}, {id: 3, year: 2023, month: 7, number: 822, active: false}, {
    id: 4, year: 2023, month: 9, number: 6, active: false
}, {id: 5, year: 2023, month: 6, number: 12, active: true},]


export function PatientDetails({setActiveTab}) {
    return (<div className="patient-details">
        <DetailTimeline name="Documents" setActiveTab={setActiveTab} cluster={initialDocs}/>
        <DetailTimeline name="Findings" setActiveTab={setActiveTab} cluster={initialFindings}/>
    </div>);
}

PatientDetails.propTypes = {
    setActiveTab: PropTypes.func.isRequired
}
