import PropTypes from "prop-types";
import {FormData, ModalData, ModalDropdownUsers, ModalHeader, StateTemplate,} from "../Components/Modals";
import {useEffect, useState} from "react";


export default function ManageUsersModal({project, members, close, manageProject}) {
    let projectMembers = convertToOptions(members.filter(member => project.members.includes(member.id)));
    projectMembers[0].isFixed = true; // Set owner to non-removable
    const formData = {
        members: new StateTemplate(projectMembers, true, false, false, validMembers),
    }
    const [form, setForm] = useState(new FormData(formData));

    function convertToOptions(users) {
        return users.map(user => ({
            value: user.id, label: user.name, image: user.image,
        }));
    }

    function validMembers(members) {
        return members.length > 0;
    }

    function updateProject() {
        const newMembersIds = form.state.members.value.map(member => member.value);
        console.log("Updating project members", newMembersIds, form);
        project.members = members.filter(member => newMembersIds.includes(member.id)).map(member => member.id);
        manageProject({type: "update", project: project});
        close();
    }

    useEffect(() => {
        const escapeCheck = (e) => {
            if (e.key === "Escape") {
                close()
            }
        }
        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [close])

    return (<div className="modal-outer">
        <div className="modal-wrapper">
            <div id="modal-add-files" className="modal-background dash-modal">
                <ModalHeader text="Add or Remove Project Members" close={close}/>
                <div className="dash-modal-row">
                    <ModalData label="Project Name" data={project.name} width="medium"/>
                    <ModalData label="Deadline" data={project.deadline} width="short"/>
                </div>
                <div className="dash-modal-row">
                    <ModalData label="Project Type" data={project.lob} width="medium"/>
                    <ModalData label="Assessment Year" data={project.assessment_year} width="short"/>
                </div>
                <ModalData label="Document Deduplication" data={'None'} width="large"/>
                <ModalData label="Description" data={project.desc} width="large"/>
                <ModalDropdownUsers label="Update Team Members"
                                    subLabel="Add up to 20 people at a time"
                                    allUsers={members}
                                    formData={form}
                                    setForm={setForm}
                                    dataId={"members"}
                                    tabIndex={2}
                />
                
                <div className="primary-button" onClick={updateProject}>
                    Confirm
                </div>
                <div className="secondary-button" onClick={close}>
                    Cancel
                </div>
            </div>
        </div>
    </div>);
}
ManageUsersModal.propTypes = {
    project: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
    manageProject: PropTypes.func.isRequired,
}