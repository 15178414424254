import PropTypes from "prop-types";
import {documents, findings, patients, practitioners} from "../Data";
import {useMatch} from "react-router-dom";
import Header from "../Header/Header";
import "./Patient.css"
import {PatientDetails} from "./PatientDetails";
import {useState} from "react";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import {documentColumns} from "./FileList";
import {findingsColumns} from "./FindingList";
import {PatientGeo} from "./PatientGeo";

export function PatientPage({members, projects, noteDispatch}) {
    const pathMatch = useMatch('/patient/:projectId/:patientUUID')
    const patientUUID = pathMatch ? pathMatch.params.patientUUID : null
    const projectId = pathMatch ? pathMatch.params.projectId : null
    const currentProject = projects.find(project => project.id.toString() === projectId.toString())
    const patient = patients.find(p => p.uuid === patientUUID)
    const currentUser = members.find(member => member.id === 1);
    const [activeTab, setActiveTab] = useState("Documents")
    return (
        <div className="app-content">
            <Header user={currentUser} noteDispatch={noteDispatch} currentProject={currentProject}/>
            <PatientHeader currentPatient={patient} currentProject={currentProject}/>
            <div className="patient-details-row">
                <PatientSummary patient={patient}/>
                <PatientDetails setActiveTab={setActiveTab}/>
            </div>
            <PatientTabs activeTab={activeTab} setActiveTab={setActiveTab} noteDispatch={noteDispatch}/>
            <PatientDataTab activeTab={activeTab} currentPatient={patient}/>
        </div>
    )
}

PatientPage.propTypes = {
    members: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    noteDispatch: PropTypes.func.isRequired
}


function PatientSummary({patient}) {
    const age = new Date().getFullYear() - new Date(patient.date_of_birth).getFullYear();
    return (
        <div className="patient-summary">
            <div className="sum-label font-12 white">Date of Birth:</div>
            <div className="sum-data font-12 white">{patient.date_of_birth}</div>
            <div className="sum-label font-12 white">Age:</div>
            <div className="sum-data font-12 white">{age}</div>
            <div className="sum-label font-12 white">Gender:</div>
            <div className="sum-data font-12 white">{patient.gender}</div>
            <div className="sum-label font-12 white">Race:</div>
            <div className="sum-data font-12 white">{patient.race}</div>
            <div className="sum-label font-12 white">Ethnicity:</div>
            <div className="sum-data font-12 white">{patient.ethnicity}</div>
            <div className="sum-label font-12 white">SSN:</div>
            <div className="sum-data font-12 white">{patient.ssn}</div>
            <div className="sum-label font-12 white">Phone:</div>
            <div className="sum-data font-12 white">{patient.phone}</div>
            <div className="sum-label font-12 white">Email:</div>
            <div className="sum-data font-12 white">{patient.email_address}</div>
            <div className="sum-label font-12 white">Street Addr:</div>
            <div className="sum-data font-12 white">{patient.street_address_line}</div>
            <div className="sum-label font-12 white">City:</div>
            <div className="sum-data font-12 white">{patient.city}</div>
            <div className="sum-label font-12 white">State:</div>
            <div className="sum-data font-12 white">{patient.state}</div>
            <div className="sum-label font-12 white">Postal Code:</div>
            <div className="sum-data font-12 white">{patient.postal_code}</div>
            <div className="sum-label font-12 white">Summary:</div>
            <div className="sum-sum font-12 white">
                {patient.summary}
            </div>
        </div>
    );
}

PatientSummary.propTypes = {
    patient: PropTypes.object.isRequired
}

export const SliderValueLabel = ({active, className, divClassName, text = "12"}) => {
    return (
        <div className={`slider-value-label ${className}`}>
            <div className={`element active-${active} ${divClassName}`}>{text}</div>
        </div>
    );
};

SliderValueLabel.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.string,
};


export const Frame = ({property1, text = "Title", text1 = "Value"}) => {
    return (
        <div className="frame">
            <div className="title">{text}</div>
            <div className="value">{text1}</div>
        </div>
    );
};

Frame.propTypes = {
    property1: PropTypes.oneOf(["default"]),
    text: PropTypes.string,
    text1: PropTypes.string,
};


export const Chip = ({state, className}) => {
    return (
        <div className={`chip ${state} ${className}`}>
            <div className="element">
                {["small-active", "small-inactive"].includes(state) && <>99</>}

                {["med-active", "med-inactive"].includes(state) && <>999</>}

                {["large-active", "large-inactive"].includes(state) && <>9999+</>}
            </div>
        </div>
    );
};

Chip.propTypes = {
    state: PropTypes.oneOf([
        "med-inactive",
        "large-active",
        "med-active",
        "small-active",
        "large-inactive",
        "small-inactive",
    ]),
};

function PatientTabs({activeTab, setActiveTab, noteDispatch}) {
    return (
        <div className="patient-tabs flex-row">
            <PatientTab label="Documents" activeTab={activeTab} setTab={setActiveTab}/>
            <PatientTab label="Findings" activeTab={activeTab} setTab={setActiveTab}/>
            <PatientTab label="Practitioners" activeTab={activeTab} setTab={setActiveTab}/>
            <PatientTab label="Map" activeTab={activeTab} setTab={setActiveTab}/>
            <PatientIconsTab noteDispatch={noteDispatch}/>
        </div>
    );
}

PatientTabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    noteDispatch: PropTypes.func.isRequired,
}

function PatientTab({label, activeTab, setTab}) {
    const active = activeTab === label ? "active" : "";

    return (
        <div className={"patient-tab " + active} onClick={() => {
            setTab(label)
        }}>
            {label}
        </div>
    );
}

PatientTab.propTypes = {
    label: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired
}


export const practitionersColumns = [
    {field: "", checkboxSelection: true, width: 64, headerCheckboxSelection: true, filter: false, resizable:false},
    {field: "practitioner_last_name", headerName: "Last Name"},
    {field: "practitioner_first_name", headerName: "First Name"},
    {field: "practitioner_npi", headerName: "NPI"},
];

function PatientDataTab({activeTab, currentPatient}) {
    function patientDocuments() {
        // TODO this a demo hack, replace with real data
        return documents.map(doc => {
            return {
                ...doc,
                patient_last_name: currentPatient.name_last,
                patient_first_name: currentPatient.name_first,
                patient_uuid: currentPatient.uuid
            }
        })
    }
    switch (activeTab) {
        case "Documents":
            return <PatientData data={patientDocuments()} columns={documentColumns}/>
        case "Findings":
            return <PatientData data={findings} columns={findingsColumns}/>
        case "Practitioners":
            return <PatientData data={practitioners} columns={practitionersColumns}/>
        case "Map":
            return <PatientGeo/>
        default:
            return <PatientData data={findings} columns={findingsColumns}/>
    }
}

PatientTabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
}

function PatientData({data, columns}) {
    return (
        <div className="ag-theme-alpine" style={{height: "100%", display:"table-row"}}>
            <AgGridReact
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                }}
                rowSelection="multiple"
                rowData={data}
                columnDefs={columns}
                gridOptions={{
                    onGridReady: (event) => event.api.sizeColumnsToFit()
                }}
            />
        </div>
    );
}

PatientData.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
}


function PatientIconsTab({noteDispatch}) {
    function download() {
        noteDispatch({
            type: 'add',
            notification: {
                text: "Download Started",
                details: "Check the 'Downloads' section of your browser",
                type: "ok"
            }
        });
    }

    return (
        <div className="patient-icons-tab float-right flex-row gap-8">
            <div className="icon-24 pointer" onClick={download}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
                        fill="#FBFBFB"/>
                </svg>
            </div>
            <div className="icon-24 pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M1.71429 16H4C4.3031 16 4.59381 15.8796 4.80809 15.6652C5.02243 15.451 5.14286 15.1602 5.14286 14.8571V1.14286C5.14286 0.839756 5.02244 0.549044 4.80809 0.334766C4.59381 0.120427 4.30309 0 4 0H1.71429C1.25964 0 0.823573 0.180602 0.50208 0.50208C0.180587 0.823573 0 1.25964 0 1.71429V14.2857C0 14.7404 0.180602 15.1764 0.50208 15.4979C0.823573 15.8194 1.25964 16 1.71429 16ZM1.14286 1.71429C1.14286 1.56274 1.20304 1.41739 1.31023 1.31023C1.41737 1.20303 1.56273 1.14286 1.71429 1.14286H4V14.8571H1.71429C1.56274 14.8571 1.41739 14.797 1.31023 14.6898C1.20303 14.5826 1.14286 14.4373 1.14286 14.2857V1.71429Z"
                        fill="#FBFBFB"/>
                    <path
                        d="M6.8577 0C6.5546 0 6.26389 0.120416 6.04961 0.334766C5.83527 0.549044 5.71484 0.839771 5.71484 1.14286V14.8571C5.71484 15.1602 5.83526 15.451 6.04961 15.6652C6.26389 15.8796 6.55462 16 6.8577 16H9.14341C9.44652 16 9.73723 15.8796 9.95151 15.6652C10.1658 15.451 10.2863 15.1602 10.2863 14.8571V1.14286C10.2863 0.839756 10.1659 0.549044 9.95151 0.334766C9.73723 0.120427 9.4465 0 9.14341 0H6.8577ZM9.14341 14.8571H6.8577V1.14286H9.14341V14.8571Z"
                        fill="#FBFBFB"/>
                    <path
                        d="M14.286 0H12.0003C11.6972 0 11.4065 0.120416 11.1922 0.334766C10.9778 0.549044 10.8574 0.839771 10.8574 1.14286V14.8571C10.8574 15.1602 10.9778 15.451 11.1922 15.6652C11.4065 15.8796 11.6972 16 12.0003 16H14.286C14.7406 16 15.1767 15.8194 15.4982 15.4979C15.8197 15.1764 16.0003 14.7404 16.0003 14.2857V1.71429C16.0003 1.25964 15.8197 0.823573 15.4982 0.50208C15.1767 0.180587 14.7406 0 14.286 0ZM14.8574 14.2857C14.8574 14.4373 14.7972 14.5826 14.69 14.6898C14.5829 14.797 14.4376 14.8571 14.286 14.8571H12.0003V1.14286H14.286C14.4375 1.14286 14.5829 1.20304 14.69 1.31023C14.7972 1.41737 14.8574 1.56273 14.8574 1.71429V14.2857Z"
                        fill="#FBFBFB"/>
                </svg>
            </div>
        </div>
    );
}

PatientIconsTab.propTypes = {
    noteDispatch: PropTypes.func.isRequired
}

export function PatientHeader({currentPatient, currentProject}) {
    return (<div className="analytics-header">
        <div className="analytics-header-left p-16-sides">
            <div className="font-17 white">{currentPatient.name_last}, {currentPatient.name_first}</div>
            <div className="font-12 grey">{currentPatient.uuid}</div>
        </div>
        <div className="analytics-header-right font-12 grey">
            <div className="flex-column align-end gap-14 p-16-sides">
                <div>Project Name:</div>
                <div>Project Type:</div>
            </div>
            <div className="flex-column align-end gap-14 p-16-sides">
                <div>{currentProject.name}</div>
                <div>{currentProject.lob}</div>
            </div>
        </div>
    </div>);
}

PatientHeader.propTypes = {
    currentPatient: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired
};