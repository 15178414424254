import Header from "../Header/Header";
import DashTile from "./DashTile";
import addUserIcon from "../images/icons/person_add.svg";
import moreIcon from "../images/icons/more_vert.svg";
import uploadIcon from "../images/icons/upload.svg";
import "./dashboard.css";
import NewProjectModal from "./NewProjectModal";
import ManageUsersModal from "./ManageUsersModal";
import AddFilesModal from "./AddFilesModal";
import {useState} from "react";
import PropTypes from "prop-types";
import {DashRows} from "./DashRows";
import {LoadingModal} from "./LoadingModal";
import 'react-contexify/ReactContexify.css';
import ProjectDetailsModal from "../Project/ProjectDetailsModal";
import {initialMembers} from "../Data";
import EditProjectDetailsModal from "../Project/EditProjectDetailsModal";
import {DashProjectOptionsDropdown} from "../Components/ProjectOptionsDropdown";
import {useContextMenu} from "react-contexify";

export default function Dashboard({projects, manageProjects, members, noteDispatch}) {
    const [showNewProjectModal, setShowNewProjectModal] = useState(false);
    const [showManageUsersModal, setShowManageUsersModal] = useState(false);
    const [addFilesModal, setAddFilesModal] = useState(false);
    const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
    const [showEditProjectDetailsModal, setShowEditProjectDetailsModal] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState(1);
    const now = new Date().valueOf();
    const [loadingTime, setLoadingTime] = useState(now - 10);
    const activeProject = projects.find(p => p.id === activeProjectId);
    const currentUser = members[0];


    function projectSettings(action, projectId) {
        console.log("Project Settings", action, projectId)
        switch (action) {
            case "show-files":
                setActiveProjectId(projectId);
                setAddFilesModal(true);
                break;
            case "show-users":
                setActiveProjectId(projectId);
                setShowManageUsersModal(true);
                break;
            case "settings":
                console.log("Settings");
                break;
            case "loading":
                setLoadingTime(new Date().valueOf() + 2000)
                console.log("Loading", loadingTime);
                break;
            case "show-details":
                setActiveProjectId(projectId);
                setShowProjectDetailsModal(true)
                break;
            case "edit-details":
                setActiveProjectId(projectId);
                setShowEditProjectDetailsModal(true)
                break;
            default:
                console.log("Unknown action");
                break;
        }

    }

    function newProject(_project) {
        manageProjects({type: 'add', project: _project});
    }


    return (
        <div className="app-content">
            <Header user={currentUser} noteDispatch={noteDispatch}/>

            <div className="app-remaining tenasol-scroll">
                <div className="dash-header">
                    <div className="p-16-sides">
                        <div className="font-16 white">Dashboard</div>
                    </div>
                    <div className="p-16-sides">
                        <div className="primary-button" style={{width: 150}}
                             onClick={() => setShowNewProjectModal(true)}>
                            + New Project
                        </div>
                    </div>
                </div>
                <DashTiles projects={projects.slice(0, 4)} members={members} settings={projectSettings}/>
                <DashRowsHeader projects={projects}/>
                <DashRows projects={projects} members={members} settings={projectSettings}/>
                {showNewProjectModal && <NewProjectModal allUsers={members}
                                                         projects={projects}
                                                         close={() => setShowNewProjectModal(false)}
                                                         settings={projectSettings}
                                                         next={(_project) => {
                                                             newProject(_project);
                                                             setShowNewProjectModal(false);
                                                             setActiveProjectId(_project.id);
                                                             setAddFilesModal(true);
                                                         }}
                                                         noteDispatch={noteDispatch}
                />}
                {showManageUsersModal && <ManageUsersModal project={activeProject}
                                                           members={members}
                                                           close={() => setShowManageUsersModal(false)}
                                                           noteDispatch={noteDispatch}
                                                           manageProject={manageProjects}
                />}
                {addFilesModal && <AddFilesModal
                    project={activeProject}
                    close={() => setAddFilesModal(false)}
                    noteDispatch={noteDispatch}
                    manageProject={manageProjects}
                    settings={projectSettings}
                />}
                {showProjectDetailsModal &&
                    <ProjectDetailsModal setShowModal={setShowProjectDetailsModal}
                                         allUsers={initialMembers}
                                         project={activeProject}
                                         settings={projectSettings}/>}
                {showEditProjectDetailsModal &&
                    <EditProjectDetailsModal setShowModal={setShowEditProjectDetailsModal}
                                             allUsers={initialMembers}
                                             project={activeProject}
                                             noteDispatch={noteDispatch}
                                             manageProject={manageProjects}/>}
                {loadingTime - now > 0 && <LoadingModal
                    loadingTime={loadingTime}
                    setLoadingTime={setLoadingTime}
                />}
            </div>
        </div>);
}
Dashboard.propTypes = {
    projects: PropTypes.array.isRequired,
    manageProjects: PropTypes.func.isRequired,
    members: PropTypes.array.isRequired,
    noteDispatch: PropTypes.func.isRequired,
}


function DashTiles({projects, members, settings}) {
    return (<div className="dash-tiles-wrapper">
        {projects.slice(0, 5).map(project => (
            <DashTile key={project.id} project={project} members={members} settings={settings}/>))}
    </div>);
}

DashTiles.propTypes = {
    projects: PropTypes.array.isRequired,
    members: PropTypes.array.isRequired,
    settings: PropTypes.func.isRequired,
}


function DashRowsHeader({projects}) {
    return (<div className="dash-projects-header">
        <div className="p-16-sides">
            <div className="secondary-button lite-theme ">ARCHIVE SELECTED</div>
        </div>
        <div className="float-right p-16-sides">
            <div className="font-12">
                {projects.length} Projects
            </div>
        </div>
    </div>);
}

DashRowsHeader.propTypes = {
    projects: PropTypes.array.isRequired,
}

export function DashIconRow({settings, projectId}) {
    const { show } = useContextMenu({
        id: "dash-more"
    });
    function showMenu(e) {
        show({
            event: e,
        });
    }
    return (<div className="icon-row-right ">
        <div className="icon-24">
            <img src={addUserIcon}
                 className="icon-blue"
                 alt="Add User"
                 onClick={() => {
                     settings("show-users", projectId)
                 }}/>
        </div>
        <div className="icon-24">
            <img src={uploadIcon}
                 className="icon-blue"
                 alt="Add Files"
                 onClick={() => {
                     settings("show-files", projectId)
                 }}/>
        </div>
        <div className="icon-24">
            <img src={moreIcon}
                 className="icon-blue"
                 alt="More"
                 onClick={showMenu}
            />

            <DashProjectOptionsDropdown settings={settings} projectId={projectId}/>
        </div>
    </div>);
}

DashIconRow.propTypes = {
    settings: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
}