import tenasolLogo from "../images/tenasol-logo.png";
import {LoginFieldset} from "./Login";
import {useState} from "react";
import emailIcon from '../images/icons/email.svg';


export function PasswordReset(props) {
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);

    function resetLoginFailed() {
        setInvalidEmail(false);
    }

    function attemptReset() {
        const emailElm = document.getElementById('reset-password-email');
        if (emailElm.value === "") {
            setInvalidEmail(true);
        } else {
            props.dispatch({type: 'add',
                notification: {
                    text: "Password Reset Email Sent",
                    details: "Please check your email for a password reset link.",
                    type: "ok"
                }
            })
            setResetComplete(true);
        }
    }

    return (
        <div id="password-reset-modal" className="modal-outer">
            <div className="modal-wrapper">
                <div className="login-modal-overlay">
                    {resetComplete ?
                        <ResetStage2 hideModal={props.hideModal} dispatch={props.dispatch}/> :
                        <ResetStage1 hideModal={props.hideModal}
                                     checkPassword={attemptReset}
                                     invalidEmail={invalidEmail}
                                     resetFailed={resetLoginFailed}/>}
                </div>
            </div>
        </div>
    );
}

function ResetStage1(props) {
    return (
        <div className="password-reset-modal">
            <a href="https://tenasol.com" className="login-tenasol-logo"><img src={tenasolLogo} alt="Tenasol"/></a>
            <div className="login-box-title font-16 white">Reset Your Password</div>
            <div className="font-10 white login-b-width align-center">Tell us the email address associated with your
                account,
                and we’ll send you an email to reset your password.
            </div>
            <LoginFieldset legend="Email"
                           type="email"
                           name="email"
                           id="reset-password-email"
                           placeholder=""
                           failed={props.invalidEmail}
                           resetfailed={props.resetFailed}/>
            <div className="primary-button login-b-width" id="reset-password-button"
                 onClick={props.checkPassword}>Reset Password
            </div>
            <div className="secondary-button login-b-width" onClick={props.hideModal}>Cancel</div>
        </div>
    );
}


function ResetStage2(props) {
    return (
        <div className="password-reset-modal">
            <a href="https://tenasol.com" className="login-tenasol-logo"><img src={tenasolLogo} alt="Tenasol"/></a>
            <div className="align-center"><img src={emailIcon} alt="Email Icon"/></div>
            <div className="login-box-title font-16 white m-8-top">Check Your Inbox</div>
            <div className="font-10 white login-b-width align-center m-8-bottom">You will receive a password reset email if the
                address you have provided has been verified.
                <br/><br/><br/>
                If you did not receive an email, please check your spam folder or try another email.
            </div>
            <div className="primary-button login-b-width" id="reset-password-button"
                 onClick={props.hideModal}>Return to Login
            </div>
        </div>
    );
}