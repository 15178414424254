import houseIcon from "./images/icons/house.svg";
import pharmacyIcon from "./images/icons/pharmacy.svg";
import shieldIcon from "./images/icons/shield.svg";
import vaccineIcon from "./images/icons/vaccine.svg";
import userProfile from "./images/UserProfile.png";

export const initialProjects = [
    {
        id: 1,
        name: "MA Risk 2023",
        lob: "CMS HCC V2423",
        assessment_year: "2023",
        members: [1, 4, 7, 11, 13, 17, 23],
        documents: 13923,
        icon: houseIcon,
        deadline: "2023-12-31",
        desc: "MA Risk 2022 Medicare Advantage",
        dedupe: "None"
    },
    {
        id: 2,
        name: "HEDIS MY2023",
        lob: "HEDIS 2023",
        assessment_year: "2023",
        members: [3, 8, 12, 16, 19, 21, 24, 29],
        documents: 891,
        icon: pharmacyIcon,
        deadline: "2022-12-31",
        desc: "HEDIS MY2023 Prospective Project",
        dedupe: "Very Restricted"
    },
    {
        id: 3,
        name: "Ascension Health",
        lob: "HEDIS 2021",
        assessment_year: "2021",
        members: [2, 5, 9, 15, 18, 22, 25, 27, 28],
        documents: 426,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "Ascension Health Risk Adjustment Project",
        dedupe: "Loose"
    },
    {
        id: 4,
        name: "Aurora Health",
        lob: "HEDIS 2021",
        assessment_year: "2021",
        members: [2, 5, 9, 15, 18, 22, 25, 27, 28],
        documents: 60448,
        icon: vaccineIcon,
        deadline: "2025-12-31",
        desc: "Aurora Health COVID-19 Vaccination Project",
        dedupe: "Very Restricted"
    },
    {
        id: 5,
        name: "ACA Risk 2023",
        lob: "HHS-HCC V07",
        assessment_year: "2023",
        members: [6, 10, 14, 20, 26],
        documents: 38,
        icon: shieldIcon,
        deadline: "2023-12-31",
        desc: "ACA Risk 2023 Affordable Care Act",
        dedupe: "Restricted"
    },
    {
        id: 6,
        name: "MA Risk 2023",
        lob: "CMS HCC V2223",
        assessment_year: "2023",
        members: [4, 8, 13, 17, 21, 23, 26, 29],
        documents: 153,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "MA Risk 2023 Medicare Advantage",
        dedupe: "Loose"
    },
    {
        id: 7,
        name: "ArcCare 2021 Review",
        lob: "HEDIS 2021",
        assessment_year: "2021",
        members: [21, 23, 26, 29],
        documents: 54115,
        icon: shieldIcon,
        deadline: "2023-12-31",
        desc: "HEDIS MY2024 Prospective Project",
        dedupe: "Restricted"
    },
    {
        id: 8,
        name: "MedSage 2023 Risk",
        lob: "HEDIS 2023",
        assessment_year: "2023",
        members: [2, 5],
        documents: 8,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "HEDIS MY2024 Prospective Project",
        dedupe: "None"
    },
    {
        id: 9,
        name: "HEDIS MY2023",
        lob: "HEDIS 2023",
        assessment_year: "2024",
        members: [21, 23, 26, 29],
        documents: 321,
        icon: shieldIcon,
        deadline: "2021-12-31",
        desc: "HEDIS MY2024 Prospective Project",
        dedupe: "Restricted"
    },
    {
        id: 10,
        name: "MarHealth Risk 2023",
        lob: "ESRD E2123",
        assessment_year: "2023",
        members: [1, 7, 15, 29],
        documents: 123,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "HEDIS MY2024 Prospective Project",
        dedupe: "Restricted"
    },
    {
        id: 11,
        name: "RADV Review 2023",
        lob: "CMS HCC 2023",
        assessment_year: "2021",
        members: [21, 23, 26, 29],
        documents: 10343,
        icon: shieldIcon,
        deadline: "2023-12-31",
        desc: "",
        dedupe: "None"
    },
    {
        id: 12,
        name: "Gcare Retro Risk 2022",
        lob: "CMS HCC V2223",
        assessment_year: "2022",
        members: [2, 18, 22],
        documents: 12323,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "Loose"
    },
    {
        id: 13,
        name: "Rio Care HEDIS 2023",
        lob: "HEDIS 2023",
        assessment_year: "2023",
        members: [7, 11, 21, 25],
        documents: 65,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "Loose"
    },
    {
        id: 14,
        name: "Hi Health HEDIS 2023",
        lob: "HEDIS 2023",
        assessment_year: "2023",
        members: [14, 20],
        documents: 122,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "Restricted"
    },
    {
        id: 15,
        name: "Rivia Health Prospective Risk 2023",
        lob: "CMS HCC V2423",
        assessment_year: "2023",
        members: [21, 23],
        documents: 2312,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "Loose"
    },
    {
        id: 16,
        name: "TaleCare 2023",
        members: [26, 29],
        lob: "HEDIS 2023",
        assessment_year: "2023",
        documents: 1003,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "None"
    },
    {
        id: 17,
        name: "Erica Health 2023 Risk Retro",
        lob: "CMS HCC V2423",
        assessment_year: "2023",
        members: [13, 17],
        documents: 843733,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "None"
    },
    {
        id: 18,
        name: "GZ Care 2023 HEDIS",
        lob: "HEDIS 2023",
        assessment_year: "2023",
        members: [14, 20, 22],
        documents: 2843,
        icon: shieldIcon,
        deadline: "2022-12-31",
        desc: "",
        dedupe: "None"
    },
]

export const initialMembers = [
    {id: 1, name: "Andrea Wilson", image: userProfile, email: "andrea@qhealth.com"},
    {id: 2, name: "Joe Eagen", image: "https://xsgames.co/randomusers/assets/avatars/male/2.jpg"},
    {id: 3, name: "Jane Temel", image: "https://xsgames.co/randomusers/assets/avatars/female/3.jpg"},
    {id: 4, name: "Jill Tane", image: "https://xsgames.co/randomusers/assets/avatars/female/4.jpg"},
    {id: 5, name: "John Tane", image: "https://xsgames.co/randomusers/assets/avatars/male/5.jpg"},
    {id: 6, name: "Max Ervin", image: "https://xsgames.co/randomusers/assets/avatars/male/6.jpg"},
    {id: 7, name: "Sara Elvin", image: "https://xsgames.co/randomusers/assets/avatars/female/7.jpg"},
    {id: 8, name: "Lily Egran", image: "https://xsgames.co/randomusers/assets/avatars/female/8.jpg"},
    {id: 9, name: "Oscar Emery", image: "https://xsgames.co/randomusers/assets/avatars/male/9.jpg"},
    {id: 10, name: "Ivy Erhart", image: "https://xsgames.co/randomusers/assets/avatars/female/10.jpg"},
    {id: 11, name: "Alex Ford", image: "https://xsgames.co/randomusers/assets/avatars/male/11.jpg"},
    {id: 12, name: "Betty Frame", image: "https://xsgames.co/randomusers/assets/avatars/female/12.jpg"},
    {id: 13, name: "Charlie Frost", image: "https://xsgames.co/randomusers/assets/avatars/male/13.jpg"},
    {id: 14, name: "Diana Gale", image: "https://xsgames.co/randomusers/assets/avatars/female/14.jpg"},
    {id: 15, name: "Evan Grant", image: "https://xsgames.co/randomusers/assets/avatars/male/15.jpg"},
    {id: 16, name: "Fiona Gray", image: "https://xsgames.co/randomusers/assets/avatars/female/16.jpg"},
    {id: 17, name: "George Green", image: "https://xsgames.co/randomusers/assets/avatars/male/17.jpg"},
    {id: 18, name: "Hannah Hale", image: "https://xsgames.co/randomusers/assets/avatars/female/18.jpg"},
    {id: 19, name: "Ian Hall", image: "https://xsgames.co/randomusers/assets/avatars/male/19.jpg"},
    {id: 20, name: "Julia Hart", image: "https://xsgames.co/randomusers/assets/avatars/female/20.jpg"},
    {id: 21, name: "Kevin Hope", image: "https://xsgames.co/randomusers/assets/avatars/male/21.jpg"},
    {id: 22, name: "Linda Hunt", image: "https://xsgames.co/randomusers/assets/avatars/female/22.jpg"},
    {id: 23, name: "Mike Joyce", image: "https://xsgames.co/randomusers/assets/avatars/male/23.jpg"},
    {id: 24, name: "Nina Kane", image: "https://xsgames.co/randomusers/assets/avatars/female/24.jpg"},
    {id: 25, name: "Oliver Lake", image: "https://xsgames.co/randomusers/assets/avatars/male/25.jpg"},
    {id: 26, name: "Patty Lane", image: "https://xsgames.co/randomusers/assets/avatars/female/26.jpg"},
    {id: 27, name: "Quentin Mars", image: "https://xsgames.co/randomusers/assets/avatars/male/27.jpg"},
    {id: 28, name: "Rachel Nash", image: "https://xsgames.co/randomusers/assets/avatars/female/28.jpg"},
    {id: 29, name: "Simon Oates", image: "https://xsgames.co/randomusers/assets/avatars/male/29.jpg"},
    {id: 30, name: "Tina Page", image: "https://xsgames.co/randomusers/assets/avatars/female/30.jpg"}
]

export const documents = [
    {
        id: 1,
        filename: "deidentified.pdf",
        file_category: "PDF",
        patient_last_name: "Lacey",
        patient_first_name: "Mckay",
        pages: 10,
        date_of_service_earliest: "2012-11-10",
        date_of_service_latest: "2012-11-10",
        priority: 0.117559,
        process_stage: ""
    },
]

export const patients = [
    {
        uuid: "2b4f9a9b-58b7-4c2c-baaf-3b61e30c7843",
        name_first: "Lacy",
        name_last: "Mckay",
        date_of_birth: "1955-05-04",
        gender: "Female",
        race: "White",
        ethnicity: "Not Hispanic or Latino",
        phone: "790-544-9294",
        ssn: "123-45-6789",
        postal_code: "32123",
        email_address: "",
        street_address_line: "",
        city: "",
        state: "",
        summary:""
    },
]

export const findings =
    [
        {
            label: "ICD-10-CM",
            hcc: 23,
            finding: "C50.911",
            confidence: null,
            value: null,
            unit: null,
            date: "2020-20-05",
            filename: "deidentified.pdf",
            page: 6,
            structured: false
        },
        {
            label: "CMS HCC V2823 Suspected Language",
            hcc: 23,
            finding: null,
            confidence: 0.982,
            value: null,
            unit: null,
            date: null,
            filename: "deidentified.pdf",
            page: 6,
            structured: false
        },
        {
            label: "ICD-10-CM",
            hcc: 23,
            finding: "C50.911",
            value: null,
            confidence: null,
            unit: null,
            date: "2020-11-18",
            filename: "deidentified.pdf",
            page: 7,
            structured: false
        },
        {
            label: "ICD-10-CM",
            hcc: 23,
            finding: "C50.911",
            value: null,
            confidence: null,
            unit: null,
            date: "2020-11-05",
            filename: "deidentified.pdf",
            page: 9,
            structured: false
        },
    ]


export const practitioners = [
    {practitioner_last_name: "Pollard", practitioner_first_name: "Greta", practitioner_npi: "9383892843"},
    {practitioner_last_name: "Santana", practitioner_first_name: "Rolf", practitioner_npi: "1047392371"},
    {practitioner_last_name: "Jacobson", practitioner_first_name: "Brandie", practitioner_npi: "2940372065"},
    {practitioner_last_name: "Gordon", practitioner_first_name: "Andrea", practitioner_npi: "1047692749"},
    {practitioner_last_name: "Kent", practitioner_first_name: "Lloyd", practitioner_npi: "7783946292"},
    {practitioner_last_name: "Middleton", practitioner_first_name: "Darnell", practitioner_npi: "2759762922"},
    {practitioner_last_name: "Dalton", practitioner_first_name: "Nanette", practitioner_npi: "4734067262"},
    {practitioner_last_name: "Carroll", practitioner_first_name: "Candice", practitioner_npi: "3869262057"},
    {practitioner_last_name: "Avery", practitioner_first_name: "Lena", practitioner_npi: "2725976938"},
    {practitioner_last_name: "Jacobs", practitioner_first_name: "Elva", practitioner_npi: "7873937586"},
    {practitioner_last_name: "Coffey", practitioner_first_name: "Otto", practitioner_npi: "5582067222"},
    {practitioner_last_name: "Arellano", practitioner_first_name: "Bertram", practitioner_npi: "9348105672"},
    {practitioner_last_name: "Huff", practitioner_first_name: "Sam", practitioner_npi: "1928308677"},
    {practitioner_last_name: "Boyle", practitioner_first_name: "Tamika", practitioner_npi: "4487670372"},
]