import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import Login from './Login/Login';
import Dashboard from "./Dashboard/Dashboard";
import Notifications from "./Components/Notifications";
import {useReducer} from "react";
import {NotificationReducer} from "./Components/NotificationReducer";
import ErrorBoundary from "./Components/ErrorBoundary";
import {ProjectsReducer} from "./Components/Projects";
import {initialMembers, initialProjects} from "./Data";
import Project from "./Project/Project";
import {FilePage} from "./Project/FilePage";
import {PatientPage} from "./Project/PatientPage";
import {TermsPage} from "./Components/TermsPage";
import {PrivacyPage} from "./Components/PrivacyPage";


export default function App() {

    const [notifications, noteDispatch] = useReducer(NotificationReducer, [], (initial) => {
        return initial;
    });

    const [projects, manageProjects] = useReducer(ProjectsReducer, initialProjects, (initial) => {
        return initial;
    });

    return (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
            
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Login dispatch={noteDispatch}/>}/>
                        <Route path="/login" element={<Login dispatch={noteDispatch}/>}/>
                        <Route path="/dashboard" element={<Dashboard projects={projects}
                                                                     manageProjects={manageProjects}
                                                                     members={initialMembers}
                                                                     noteDispatch={noteDispatch}/>}/>
                        <Route path="/project/*" element={<Project projects={projects}
                                                                   members={initialMembers}
                                                                   noteDispatch={noteDispatch}
                        />}/>
                        <Route path="/file/*" element={<FilePage members={initialMembers}
                                                                 noteDispatch={noteDispatch}
                        />}/>
                        <Route path="/patient/*" element={<PatientPage members={initialMembers}
                                                                       projects={projects}
                                                                       noteDispatch={noteDispatch}
                        />}/>
                        <Route path="/terms" element={<TermsPage members={initialMembers}
                                                                       noteDispatch={noteDispatch}
                        />}/>
                        <Route path="/privacy" element={<PrivacyPage members={initialMembers}
                                                                       noteDispatch={noteDispatch}
                        />}/>
                    </Routes>
                    <Notifications notifications={notifications} dispatch={noteDispatch}/>
                </div>
            </BrowserRouter>
        </ErrorBoundary>
    );
}
