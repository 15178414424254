import closeIcon from '../images/icons/close.svg';
import cloudIcon from '../images/icons/cloud.svg';
import PropTypes from "prop-types";
import React, {useState} from "react";
import "./Modals.css";
import Select, {components, MultiValueGenericProps, OptionProps} from 'react-select'
import {useDropzone} from 'react-dropzone';
import UserIcon from "./UserIcon";
import gearIcon from '../images/icons/gear.svg';

export class StateTemplate {

    defaultValidate = () => {
        return true;
    }

    constructor(value, required = false, valid = false, errored = false, validate) {
        this.value = value;
        this.valid = valid;
        this.required = required;
        this.errored = errored;
        this.validate = validate ? validate : this.defaultValidate;
    }
}

export function validateText(value) {
    return value.length > 0;
}

export function validateInput(value) {
    return value !== undefined && value !== null && value.value !== "";
}

export class FormData {
    constructor(state) {
        this.state = state
    }

    setValue(key, value) {
        this.state[key].value = value;
        this.state[key].errored = false;
        this.state[key].valid = this.state[key].validate(value);
    }

    setErrored(key, errored) {
        this.state[key].errored = errored;
    }

    setValid(key, valid) {
        this.state[key].valid = valid;
    }

    value(key) {
        return this.state[key].value;
    }

    required(key) {
        return this.state[key].required;
    }

    valid(key) {
        return this.state[key].valid;
    }

    errored(key) {
        return this.state[key].errored;
    }

    keys() {
        return Object.keys(this.state);
    }

    validate() {
        console.log(this)
        let valid = true;
        for (let key in this.state) {
            if (this.required(key) && !this.valid(key)) {
                valid = false;
                this.setErrored(key, true);
            } else {
                this.setErrored(key, false);
            }
        }
        console.log(this);
        return valid;
    }

    toString() {
        return JSON.stringify(this.state);
    }
}


export function ModalHeader(props) {
    return (<div className="modal-header font-16 white bold">
        <span>{props.text}</span>
        <img style={{cursor: "pointer"}} src={closeIcon} alt="Close" onClick={props.close}/>
    </div>)
}

export function EditModalHeader({text, close, settings}) {
    return (<div className="modal-header font-16 white bold">
        <span>{text}</span>
        <div className="icon-row-right">
            <img className="pointer" src={gearIcon} alt="Edit"/>
            <img className="pointer" src={closeIcon} alt="Close" onClick={close}/>
        </div>
    </div>)
}

EditModalHeader.propTypes = {
    text: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    settings: PropTypes.func.isRequired,
}


const FormTitle = ({label, status, required}) => {
    return (<div className={`form-title font-11 ${status}`}>
        <p>{label} {required && <span className={status}>*</span>}</p>
    </div>);
}
FormTitle.propTypes = {
    label: PropTypes.string, status: PropTypes.oneOf(["", "active", "errored"]), required: PropTypes.bool,
}

const FormSubTitle = ({subLabel}) => {
    return (<div className="form-subtitle font-10">
        <span>{subLabel}</span>
    </div>);
}
FormSubTitle.propTypes = {
    subLabel: PropTypes.string,
}


export const ModalTextForm = ({
                                  label = "",
                                  subLabel = "",
                                  placeholder = "",
                                  size = "",
                                  formData,
                                  setForm,
                                  dataId,
                                  tabIndex
                              }) => {
    const errored = formData.state[dataId].errored ? "errored" : "";
    const [active, setActive] = useState("");
    const status = errored || active;
    const value = formData.state[dataId].value;
    const required = formData.state[dataId].required;

    const onFocus = () => {
        setActive("active");
    }

    const onBlur = () => {
        setActive("");
    }

    function change(e) {
        const value = e.target.value;
        setForm(_ => {
            formData.setValue(dataId, value);
            return new FormData(formData.state)
        });
    }


    return (<form className={`modal-form ${status} ${size}`} onFocus={onFocus} onBlur={onBlur}>
        <FormTitle label={label} status={status} required={required}/>
        <input className={"form-text font-12 " + size}
               type="text"
               placeholder={placeholder}
               value={value}
               onChange={change}
               autoFocus={tabIndex === 1}
               tabIndex={tabIndex}
        />
        <FormSubTitle subLabel={subLabel}/>
    </form>);
};

ModalTextForm.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    size: PropTypes.oneOf(["large", "medium", "short", "vshort"]),
    placeholder: PropTypes.string,
    formData: PropTypes.object.isRequired,
    dataId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
};

const dropdownStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: "#00000000",
        border: state.isFocused ? "1px solid #fdfdfd" : "1px solid #616161",
        "&:hover": {
            boxShadow: "0 0 2px 2px #616161", border: "1px solid #616161",
        },
        "&:focus-within": {
            boxShadow: "0 0 0 0px #616161", border: "1px solid #fdfdfd",
        },
        color: "#888888",
        borderRadius: "4px",
        minHeight: "40px",
    }),
    singleValue: styles => ({...styles, color: "#FDFDFD"}),
    input: styles => ({...styles, color: "#888888"}),
    menu: styles => ({...styles, backgroundColor: "#233243", color: "#B7BCC0", border: "1px solid #888888"}),
    option: (styles, {isFocused, isSelected}) => {
        return {
            ...styles, backgroundColor: isSelected ? "#6A8EA9" : isFocused ? "#2D3D49" : "#233243",
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#2D3D49", color: "#FDFDFD", borderRadius: 999};
    },
    multiValueLabel: (base, state) => {
        return {
            ...base,
            backgroundColor: "#2D3D49",
            color: "#FDFDFD",
            borderRadius: 999,
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '13pt',
            fontWeight: 'lighter',
        };
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? {...base, display: 'none', marginRight: '10px'} : {
            ...base,
            backgroundColor: "#2D3D49",
            color: "#FDFDFD",
            borderRadius: 999,
            paddingLeft: '6px',
            paddingRight: '6px',
            ':hover': {
                backgroundColor: "#6A8EA9",
            },
        };
    },
};

const MultiValueLabel = (props: MultiValueGenericProps) => {
    return (<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <UserIcon user={props.data} sizeOverride="25px"/>
        <components.MultiValueLabel {...props} children={props.children}/>
    </div>);
};

const Option = (props: OptionProps) => {
    function children() {
        return (<div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: '8px'}}>
            <UserIcon user={props.data} sizeOverride="30px"/>
            {props.children}
        </div>);
    }

    return (<components.Option {...props} children={children()}/>);
};

export const ModalDropdownForm = ({
                                      label = "",
                                      subLabel = "",
                                      size,
                                      options = [],
                                      formData,
                                      setForm,
                                      dataId,
                                      tabIndex,
                                      autoFocus
                                  }) => {
    const errored = formData.state[dataId].errored ? "errored" : "";
    const [active, setActive] = useState("");
    const status = errored || active;
    const value = options.find(option => option.value === formData.state[dataId].value.value);
    const required = formData.state[dataId].required;

    const onFocus = () => {
        setActive("active");
    }

    const onBlur = () => {
        setActive("");
    }

    function change(value) {
        setForm(_ => {
            formData.setValue(dataId, value);
            return new FormData(formData.state)
        });
    }

    return (<form className={`modal-form ${status} ${size}`} onFocus={onFocus} onBlur={onBlur}>
        <FormTitle label={label} status={status} required={required}/>
        <Select className={`form-drop font-12 ${size}`}
                options={options}
                styles={dropdownStyles}
                required={required}
                value={value}
                onChange={change}
                components={{
                    IndicatorSeparator: () => null,
                }}
                tabIndex={tabIndex}
                autoFocus={autoFocus}
        />
        <FormSubTitle subLabel={subLabel}/>
    </form>);
};

ModalDropdownForm.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    size: PropTypes.oneOf(["large", "medium", "short", "vshort"]),
    required: PropTypes.bool,
    options: PropTypes.array,
    formData: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
    dataId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
    autoFocus: PropTypes.bool,
};

export const UserIconDropdown = ({user}) => {
    return (<div className="user-icon-dropdown">
        <UserIcon user={user} sizeOverride="30px"/>
        <div className="user-icon-dropdown-name font-12 white">{user.name}</div>
    </div>);

}


export const ModalUsersStatic = ({
                                     label = "",
                                     subLabel = "",
                                     users = [],
                                 }) => {

    return (
        <div className={`modal-form large`}>
            <FormTitle label={label}/>
            <div className="modal-users-static">
                {users.map(user => <UserIconDropdown key={user.id} user={user}/>)}
            </div>
            <FormSubTitle subLabel={subLabel}/>
        </div>
    );
};

ModalUsersStatic.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    users: PropTypes.array,
};

export const ModalDropdownUsers = ({
                                       label = "",
                                       subLabel = "",
                                       required = false,
                                       allUsers = [],
                                       formData,
                                       setForm,
                                       dataId,
                                       tabIndex
                                   }) => {
    const errored = formData.state[dataId].errored ? "errored" : "";
    const [active, setActive] = useState("");
    const status = errored || active;
    const users = formData.state[dataId].value;
    const [options, setOptions] = useState(convertToOptions(allUsers));

    function convertToOptions(users) {
        function fixed(user) {
            return users.find(u => u.id === user.id).isFixed;
        }

        return users.map(user => ({
            value: user.id,
            label: user.name,
            image: user.image,
            isFixed: fixed(user),
        }));
    }

    const onFocus = () => {
        setActive("active");
    }

    const onBlur = () => {
        setActive("");
    }

    function unusedIOptions(selectedOptions) {
        let newOptions = []
        for (let option of options) {
            if (!selectedOptions.find(o => o.value === option.value) || option.isFixed) {
                newOptions.push(option);
            }
        }
        return newOptions;
    }


    const onChange = (newValues, actionMeta) => {
        switch (actionMeta.action) {
            case 'select-option':
            case 'set-value':
                if (actionMeta.option.isDisabled) {
                    return;// Don't add the value
                }
                break;
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValues = users.filter((v) => v.isFixed);
                break;
            default:
                break;
        }
        setOptions(unusedIOptions(newValues));
        setForm(_ => {
            formData.setValue(dataId, newValues);
            return new FormData(formData.state)
        });
    };


    return (<form className={`modal-form large ${status}`} onFocus={onFocus} onBlur={onBlur}>
        <FormTitle label={label} status={status} required={required}/>
        <Select className={"form-drop font-12 large"}
                isMulti
                closeMenuOnSelect={false}
                value={users}
                options={options}
                styles={dropdownStyles}
                required={required}
                onChange={onChange}
                components={{
                    IndicatorSeparator: () => null, MultiValueLabel, Option
                }}
                tabIndex={tabIndex}
        />
        <FormSubTitle subLabel={subLabel}/>
    </form>);
};

ModalDropdownUsers.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    required: PropTypes.bool,
    allUsers: PropTypes.array,
    lockedUsers: PropTypes.array,
    formData: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
    dataId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number.isRequired,
};


export const ModalTextAreaForm = ({
                                      label = "",
                                      subLabel = "",
                                      placeholder = "",
                                      size,
                                      formData,
                                      setForm,
                                      dataId,
                                      autoFocus,
                                      tabIndex
                                  }) => {
    const errored = formData.state[dataId].errored ? "errored" : "";
    const [active, setActive] = useState("");
    const status = errored || active;
    const value = formData.state[dataId].value;
    const required = formData.state[dataId].required;
    const onFocus = () => {
        setActive("active");
    }

    const onBlur = () => {
        setActive("");
    }

    function change(e) {
        const value = e.target.value;
        setForm(_ => {
            formData.setValue(dataId, value);
            return new FormData(formData.state)
        });
    }

    return (
        <form className={`modal-form ${status} ${size}`} onFocus={onFocus} onBlur={onBlur}>
            <FormTitle label={label} status={status} required={required}/>
            <textarea className={"form-text font-12 " + size}
                      style={{resize: "none", height: "160px"}}
                      placeholder={placeholder}
                      value={value}
                      onChange={change}
                      tabIndex={tabIndex}
                      autoFocus={autoFocus}
            />
            <FormSubTitle subLabel={subLabel}/>
        </form>
    );
};

ModalTextAreaForm.propTypes = {
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string,
    size: PropTypes.oneOf(["large", "medium", "short", "vshort"]),
    placeholder: PropTypes.string,
    formData: PropTypes.object.isRequired,
    dataId: PropTypes.string.isRequired,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
};


export const ModalData = ({label, data, width}) => {
    return (<div className="modal-form">
        <div className={width}>
            <div className="modal-data-label font-10 white">{label}</div>
            <div className="modal-data-text font-12 white bold">{data}</div>
        </div>
    </div>);
}

ModalData.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    width: PropTypes.oneOf(["short", "medium", "large"]),
}


export function ModalFileDrop({files, setFiles}) {

    const onDrop = React.useCallback((acceptedFiles) => {
        setFiles(prev => [...prev, ...acceptedFiles]);
    }, [setFiles]);
    const {getRootProps, getInputProps} = useDropzone({
        onDrop, accept: {
            'application/zip': [],
            'application/pdf': [],
            'application/xml': [],
            'application/rtf': [],
            'application/json': [],
            'image/jpeg': [],
            'image/png': [],
            'image/tiff': [],
        }
    });

    const fileList = files.map(file => (<li key={file.path}>
        {file.path} - {file.size} bytes
    </li>));

    return (
        <>
            <section className="dropzone-container">
                <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                    <div>
                        <img src={cloudIcon} alt="Upload"/>
                    </div>
                    <div className="bold font-12 white">Drag and drop your files here</div>
                    <div className="font-12 white">(*.zip, *.pdf, *.xml, *.rtf, *.json, or most image formats;
                        <br/> *.zip of multiple files accepted)
                    </div>
                    <div className="bold font-12 white" style={{padding: "16px 0"}}>or</div>
                    <div>
                        <div className="secondary-button" style={{width: 130}}>Browse</div>
                    </div>
                </div>
            </section>
            <aside id="filelist" className="white font-12">
                {files.length > 0 && <><h4>Files</h4>
                    <ul>{fileList}</ul>
                </>}
            </aside>
        </>
    )
        ;
}

ModalFileDrop.propTypes = {
    files: PropTypes.array.isRequired, setFiles: PropTypes.func.isRequired,
}