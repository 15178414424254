import {AgGridReact} from "ag-grid-react";
import PropTypes from "prop-types";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import {File} from "./FileList";
import downloadIcon from "../images/icons/download.svg";
import moreIcon from "../images/icons/more_vert.svg";
import {findings} from "../Data";

export function FindingList({currentProject, noteDispatch}) {

    function downloadSelected() {
        noteDispatch({
            type: "add", notification: {
                text: "Download Started", details: "Check the 'Downloads' section of your browser", type: "ok"
            }
        });
    }

    return (
        <>
            <FindingHeader numFindings={findings.length} currentProject={currentProject}
                           downloadSelected={downloadSelected}/>
            <div className="ag-theme-alpine ">
                <AgGridReact
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}
                    rowSelection="multiple"
                    rowData={findings}
                    columnDefs={findingsColumns}
                    gridOptions={{
                        onGridReady: (event) => event.api.sizeColumnsToFit()
                    }}
                />
            </div>
        </>
    )
}

FindingList.propTypes = {
    currentProject: PropTypes.object.isRequired,
    noteDispatch: PropTypes.func.isRequired
};


export function FindingHeader({currentProject, downloadSelected, numFindings}) {
    return (<div className="project-header">
        <div className="p-16-sides flex-row gap-16">
            <div className="secondary-button lite-theme"
                 onClick={() => {
                     return true;
                 }}>
                EDIT
            </div>
            <div className="secondary-button lite-theme"
                 onClick={() => {
                     return true;
                 }}>
                ARCHIVE
            </div>
        </div>
        <div className="float-right flex-row gap-16 p-16-sides">
            <div>Project: {currentProject.name}</div>
            <div>{numFindings.toLocaleString()} Findings</div>
            <div className="icon-row-right pointer" onClick={downloadSelected}><img src={downloadIcon}
                                                                                    alt="Download"/>
            </div>
            <div className="icon-row-right pointer"><img src={moreIcon} alt="More"/></div>
        </div>
    </div>);
}

FindingHeader.propTypes = {
    currentProject: PropTypes.object.isRequired,
    downloadSelected: PropTypes.func.isRequired,
    numFindings: PropTypes.number.isRequired

};

export const findingsColumns = [
    {field: "", checkboxSelection: true, width: 64, headerCheckboxSelection: true, filter:false, resizable:false},
    {field: "hcc", headerName: "HCC",width: 100,},
    {field: "label", headerName: "Label",width: 300,},
    {field: "finding", headerName: "Finding",width: 100,},
    // {field: "value", headerName: "Value"},
    // {field: "unit", headerName: "Unit"},
    {field: "date", headerName: "Date"},
    {field: "filename", headerName: "File Name", cellRenderer: File},
    {field: "page", headerName: "Page"},
    {field: "structured", headerName: "Structured", cellRenderer: Structured},
];

function Structured({value}) {
    return <div>{value ? "Yes" : "No"}</div>;
}