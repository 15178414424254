import PropTypes from "prop-types";
import React, {useEffect} from 'react';
import {EditModalHeader, ModalData, ModalHeader, ModalUsersStatic} from '../Components/Modals';


export default function ProjectDetailsModal({setShowModal, allUsers, project, settings}) {

    const projectUsers = allUsers.filter(user => project.members.includes(user.id))
    useEffect(() => {
        const escapeCheck = (e) => {
            if (e.key === "Escape") {
                setShowModal(false);
            }
        }
        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [setShowModal])




    return (<div className="modal-outer">
        <div className="modal-wrapper">
            <div id="modal-add-files" className="modal-background dash-modal">
                <EditModalHeader text="View Project Details"
                             close={() => setShowModal(false)}
                />
                <div className="dash-modal-row">
                    <ModalData label="Project Name" data={project.name} width="medium"/>
                    <ModalData label="Deadline" data={project.deadline} width="short"/>
                </div>
                <div className="dash-modal-row">
                    <ModalData label="Project Type" data={project.lob} width="medium"/>
                    <ModalData label="Assessment Year" data={project.assessment_year} width="short"/>
                </div>
                <ModalData label="Document Deduplication" data={project.dedupe} width="large"/>
                <ModalData label="Description" data={project.desc} width="large"/>
                <ModalUsersStatic label="Team Members"
                                  users={projectUsers}
                />
                <div className="primary-button" onClick={() => setShowModal(false)}>
                    Close
                </div>
                <div className="secondary-button" onClick={() => {
                    settings("edit-details", project.id)
                    setShowModal(false)
                }}>
                    Edit
                </div>
            </div>
        </div>
    </div>);
}

ProjectDetailsModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    allUsers: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    settings: PropTypes.func.isRequired,
}


