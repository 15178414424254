import PropTypes from "prop-types";
import {Item, Menu} from "react-contexify";

export function DashProjectOptionsDropdown({projectId, settings}) {
    function handleSelectChange({id, props, data, triggerEvent}) {
        switch (id) {
            case "view":
                settings("show-details", projectId);
                break;
            case "edit":
                settings("edit-details", projectId);
                break;
            default:
                console.log('changing selected option');
                break;
        }
    }

    return (
        <Menu id="dash-more" animation={false}>
            <Item id="view" className="dash-more-dropdown" onClick={handleSelectChange}>View Details</Item>
            <Item id="edit" className="dash-more-dropdown" onClick={handleSelectChange}>Edit Details</Item>
        </Menu>
    );

}

DashProjectOptionsDropdown.propTypes = {
    projectId: PropTypes.number.isRequired,
    settings: PropTypes.func.isRequired,
};
