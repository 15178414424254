import CollaboratorsRow from "../Components/CollaboratorsRow";
import {DashIconRow} from "./Dashboard";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

function DashTileBubble(props) {
    return (<div className="project-tile-bubble">
        <div className="font-9">{props.value.toLocaleString()}</div>
    </div>);
}

function DashHeader({name, icon, target}) {
    return (<Link className="project-tile-head stretch no-underline" to={target}>
        <div className="project-tile-head-icon">
            <div className="icon-24">
                <img src={icon} alt="project icon"/>
            </div>
        </div>
        <div className="font-16 white">
            {name}
        </div>
    </Link>);
}
DashHeader.propTypes = {
    name: PropTypes.string.isRequired, icon: PropTypes.string.isRequired, target: PropTypes.string.isRequired
}


export default function DashTile({project, members, settings}) {
    const projectLink = `/project/${project.id}`;
    return (<div className="project-tile">
        <DashTileBubble value={project.documents}/>
        <DashHeader name={project.name} icon={project.icon} target={projectLink}/>
        <div className="project-tile-body stretch">
            <div className="stretch">
                <CollaboratorsRow collaborators={project.members}
                                  members={members}
                                  projectId={project.id} settings={settings}/>
                <DashIconRow settings={settings} projectId={project.id}/>
            </div>
        </div>
    </div>);
}
DashTile.propTypes = {
    project: PropTypes.object.isRequired, members: PropTypes.array.isRequired, settings: PropTypes.func.isRequired,
}