import {AgGridReact} from "ag-grid-react";
import PropTypes from "prop-types";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import {Link} from "react-router-dom";
import downloadIcon from "../images/icons/download.svg";
import moreIcon from "../images/icons/more_vert.svg";
import {patients} from "../Data";
import {useContextMenu} from "react-contexify";

export function PatientList({currentProject, noteDispatch}) {

    function downloadSelected() {
        noteDispatch({
            type: "add", notification: {
                text: "Download Started", details: "Check the 'Downloads' section of your browser", type: "ok"
            }
        });
    }

    return (<>
        <PatientHeader currentProject={currentProject}
                       downloadSelected={downloadSelected}
                       numPatients={patients.length}/>
        <div className="ag-theme-alpine ">
            <AgGridReact
                defaultColDef={{
                    sortable: true, filter: true, resizable: true,
                }}
                rowSelection="multiple"
                rowData={patients}
                columnDefs={patientColumns}
                gridOptions={{
                    onGridReady: (event) => event.api.sizeColumnsToFit()
                }}
            />
        </div>
    </>)
}

PatientList.propTypes = {
    currentProject: PropTypes.object.isRequired, noteDispatch: PropTypes.func.isRequired
};

export function PatientHeader({currentProject, downloadSelected, numPatients}) {
    return (<div className="project-header">
        <div className="p-16-sides flex-row gap-16">
            <div className="secondary-button lite-theme"
                 onClick={() => {
                     return true;
                 }}>
                EDIT
            </div>
            <div className="secondary-button lite-theme"
                 onClick={() => {
                     return true;
                 }}>
                ARCHIVE
            </div>
        </div>
        <div className="float-right flex-row gap-16 p-16-sides">
            <div>Project: {currentProject.name}</div>
            <div>{numPatients.toLocaleString()} Patients</div>
            <div className="icon-row-right pointer" onClick={downloadSelected}><img src={downloadIcon}
                                                                                    alt="Download"/>
            </div>
            <div className="icon-row-right pointer"><img src={moreIcon} alt="More"/></div>
        </div>
    </div>);
}

PatientHeader.propTypes = {
    currentProject: PropTypes.object.isRequired, downloadSelected: PropTypes.func.isRequired
};


export const patientColumns = [{
    field: "", checkboxSelection: true, width: 64, headerCheckboxSelection: true, filter:false, resizable:false
}, {field: "name_last", headerName: "Last Name", cellRenderer: LastName}, {
    field: "name_first", cellRenderer: LastName, headerName: "First Name"
}, {field: "date_of_birth", headerName: "Date Of Birth"}, {field: "gender", headerName: "Gender"}, {
    field: "race", headerName: "Race"
}, {field: "ethnicity", headerName: "Ethnicity"},];


function LastName(props) {
    const name = props.value;
    const data = props.data;
    const uuid = data.uuid;
    return (<Link to={"/patient/1/" + uuid}>{name}</Link>)
}
