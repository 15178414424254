import "./Notification.css"
import okIcon from "../images/icons/ok.svg";
import errorIcon from "../images/icons/error.svg";
import warningIcon from "../images/icons/warning.svg";
import closeIcon from "../images/icons/close.svg";


export default function Notification(props) {
    return (
        <div className="notification" style={{marginBottom: props.index * 7, marginRight: props.index * 3}}>
            <div className="notification-header">
                <div className="notification-icon">
                    <NotificationIcon type={props.type}/>
                </div>
                <div className="notification-text">
                    {props.text}
                </div>
                <div className="notification-close" onClick={props.close}>
                    <img src={closeIcon} alt="Close"/>
                </div>
            </div>
            {props.details && <div className="notification-details">{props.details}</div>}
        </div>
    );
}

function NotificationIcon(props) {
    const iconMap = {
        "ok": okIcon,
        "error": errorIcon,
        "warning": warningIcon
    }
    return (
        <img src={iconMap[props.type]} alt={props.type}/>
    );
}