import CollaboratorIcon from "./CollaboratorIcon";
import PropTypes from "prop-types";
import ErrorBoundary from "./ErrorBoundary";

export default function CollaboratorsRow({collaborators, members, projectId, settings}) {
    return (
        <ErrorBoundary>
            <div className="collaborators-row" onClick={() => {
                settings("show-users", projectId)
            }}>
                {collaborators.slice(0, 4).map((memberId) => (
                    <CollaboratorIcon key={memberId} member={members.find(member => member.id === memberId)}/>
                ))}
                {collaborators.length > 4 &&
                    <div className="collaborators-additional font-12">+{collaborators.length - 4}</div>
                }
            </div>
        </ErrorBoundary>
    );
}
CollaboratorsRow.propTypes = {
    collaborators: PropTypes.array.isRequired,
    members: PropTypes.array.isRequired,
    settings: PropTypes.func.isRequired
}