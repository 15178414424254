import {ModalData, ModalFileDrop, ModalHeader,} from "../Components/Modals";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";


export default function AddProjectsModal({project, close, noteDispatch, manageProject, settings}) {
    const [files, setFiles] = useState([]);

    function addFiles() {
        if (files.length === 0) {
            noteDispatch({
                type: 'add',
                notification: {
                    text: "Project Created, But No Files Added",
                    details: "To add files, access the project settings on the dashboard and select “Add Files to Project.”",
                    type: "warning"
                }
            });
            return;
        }
        const plural = files.length > 1 ? 's' : '';
        const details = `${files.length} file${plural} added to the '${project.name}' project.`;
        noteDispatch({type: 'add', notification: {text: "Files Added", details: details, type: "ok"}});
        project.documents += files.length;
        manageProject({type: "update", project: project});
        settings('loading', project.id);
        close();
    }


    useEffect(() => {
        function escapeCheck(e) {
            if (e.key === 'Escape') tryCloseEffect();
        }

        function tryCloseEffect() {
            const plural = files.length > 1 ? 's' : '';
            const details = `${files.length} file${plural} has not been uploaded.`
            if (files.length > 0) {
                noteDispatch({
                    type: 'add',
                    notification: {text: `File${plural} Not Uploaded`, details: details, type: "warning"}
                });
                return;
            }
            close();
        }

        window.addEventListener('keydown', escapeCheck)
        return () => window.removeEventListener('keydown', escapeCheck)
    }, [close, files, noteDispatch])

    return (
        <div className="modal-outer">
            <div className="modal-wrapper">
                <div id="modal-add-files" className="modal-background dash-modal">
                    <ModalHeader text="Add Files To Project" close={close}/>
                    <div className="dash-modal-row">
                        <ModalData label="Project Name" data={project.name} width="medium"/>
                        <ModalData label="Deadline" data={project.deadline} width="short"/>
                    </div>
                    <div className="dash-modal-row">
                        <ModalData label="Project Type" data={project.lob} width="medium"/>
                        <ModalData label="Assessment Year" data={project.assessment_year} width="short"/>
                    </div>
                    <ModalData label="Document Deduplication" data={'None'} width="large"/>
                    <ModalData label="Description" data={project.desc} width="large"/>
                    <ModalFileDrop files={files} setFiles={setFiles}/>
                    <div className="primary-button" onClick={addFiles}>
                        Confirm
                    </div>
                    <div className="secondary-button" onClick={close}>
                        I'll Do It Later
                    </div>
                </div>
            </div>
        </div>
    )
}

AddProjectsModal.propTypes = {
    project: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    noteDispatch: PropTypes.func.isRequired,
    manageProject: PropTypes.func.isRequired,
    settings: PropTypes.func.isRequired
}