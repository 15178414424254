import {useState} from "react";
import LoginHeader from "./LoginHeader";
import './Login.css';
import tenasolLogo from '../images/tenasol-logo.png';
import {PrivacyPolicyModal} from "./PrivacyPolicyModal";
import {PasswordReset} from "./PasswordReset";
import {LoadingModal} from "../Models/LoadingModal";
import {TwoFA} from "./TwoFA";

export default function Login(props) {
    const [loginFailed, setLoginFailed] = useState(false);
    const [privacyModalEnabled, setPrivacyModalEnabled] = useState(false);
    const [resetPasswordModalEnabled, setResetPasswordModalEnabled] = useState(false);
    const [twoFactorModalEnabled, setTwoFactorModalEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    function attemptLogin() {
        const passwordElm = document.getElementById('login-password');
        if (passwordElm.value === "password987") {
            setTwoFactorModalEnabled(true);
        } else {
            setLoginFailed(true)
        }
    }


    return (
        <div id="login">
            <LoginHeader/>
            {!resetPasswordModalEnabled && <LoginBox loginFailed={loginFailed}
                                                     showPrivacyModal={() => setPrivacyModalEnabled(true)}
                                                     attemptLogin={attemptLogin}
                                                     showResetModal={() => {
                                                         setResetPasswordModalEnabled(true)
                                                     }}
                                                     resetLoginFailed={() => {
                                                         setLoginFailed(false)
                                                     }}
            />
            }
            {privacyModalEnabled && <PrivacyPolicyModal hideModal={() => {
                setPrivacyModalEnabled(false)
            }}/>}
            {resetPasswordModalEnabled && <PasswordReset hideModal={() => {
                setResetPasswordModalEnabled(false)
            }} dispatch={props.dispatch}/>}
            {twoFactorModalEnabled && <TwoFA
                hideModal={() => {
                    setTwoFactorModalEnabled(false)
                }}
                dispatch={props.dispatch}
                setLoading={setLoading}
            />}
            {loading && <LoadingModal/>}
        </div>
    );
}


export function LoginFieldset(props) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (props.complete) {
                props.complete();
            }
        }
    }
    return (
        <fieldset className={props.failed ? "error" : "content-ok"}>
            <legend>{props.legend}</legend>
            <input type={props.type}
                   name={props.name}
                   id={props.id}
                   placeholder={props.placeholder}
                   value={props.value}
                   onChange={props.resetfailed}
                   onKeyDown={handleKeyDown}
                   className="font-16 white"
                   autoFocus={props.active}
                   tabIndex={props.tabIndex}
            />
        </fieldset>
    );
}

function LoginBox(props) {
    return (
        <div id="login-box-center">
            <div id="login-box">
                <a href="https://tenasol.com" className="login-tenasol-logo"><img src={tenasolLogo} alt="Tenasol"/></a>
                <div className="login-box-title font-16">Platform Access</div>
                <LoginFieldset legend="Email"
                               type="email"
                               name="email"
                               id="login-email"
                               placeholder="Email"
                               failed={props.loginFailed}
                               resetfailed={props.resetLoginFailed}
                               active={true}
                               tabIndex={1}
                />
                <LoginFieldset legend="Password"
                               type="password"
                               name="password"
                               id="login-password"
                               placeholder="Password"
                               failed={props.loginFailed}
                               resetfailed={props.resetLoginFailed}
                               complete={props.attemptLogin}
                               tabIndex={2}
                />
                <div id="login-failed-message"
                     className="font-12">{props.loginFailed ? "Invalid email or password." : ''}</div>
                <button id="login-button" onClick={props.attemptLogin} className="primary-button login-b-width"
                        tabIndex={3}>
                    <div>Log in</div>
                </button>
                <div id="login-box-footer" className="font-10 white">
                    <div><span>Forgot your </span><span className="underline"
                                                        onClick={props.showResetModal}>password</span><span>?</span>
                    </div>
                    <span id="login-privacy-policy" className="underline" onClick={props.showPrivacyModal}>Privacy Policy</span>
                </div>
            </div>
        </div>
    );
}
