import PropTypes from "prop-types";
import {Link} from "react-router-dom";


export function SidebarIcon({active = false, icon, subLink}) {
    const activeClass = active ? "active" : "";
    return (
        <Link className={`sidebar-icon`} to={subLink}>
            <div className={activeClass}>
                {icon}
            </div>
        </Link>
    )
}

SidebarIcon.propTypes = {
    active: PropTypes.bool.isRequired,
    icon: PropTypes.element.isRequired,
    subLink: PropTypes.string.isRequired
}